











import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { gettersEvents } from "@/store/Graphics";
import axios from "axios";

@Component({
  components: {
    apexcharts: VueApexCharts
  }
})
export default class GraphicsAvailability extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() token!: string;
  @Prop() sgpaapiurl!: string;
  @Prop() bus!: Vue;
  /* data */
  series = [
    {
      name: "Disponibilidade",
      type: "area",
      data: []
    },
    {
      name: "Média",
      type: "line",
      data: []
    }
  ];
  chartOptions = {
    xaxis: {
      categories: []
    },
    chart: {
      height: 350,
      type: "line"
    },
    stroke: {
      curve: "straight"
    },
    fill: {
      type: "solid",
      opacity: [0.35, 1]
    },
    markers: {
      size: 8
    },
    yaxis: [
      {
        min: 0,
        max: 100,
        title: {
          text: "Disponibilidade(%)"
        }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function(y: number) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + "%";
          }
          return y;
        }
      }
    }
  };

  /* Computed */
  get dateStart() {
    return gettersEvents.getFilterFailures().dateStart;
  }

  get dateEnd() {
    return gettersEvents.getFilterFailures().dateEnd;
  }

  /* Hooks */
  created() {
    this.bus.$on("filterChild", () => {
      this.getHistoricoDisponibilidade();
    });
  }
  /* methods */
  getHistoricoDisponibilidade() {
    const f = (accumulator: string, currentValue: string) => accumulator + currentValue;
    const dtStart = this.dateStart
      .split("-")
      .reverse()
      .reduce(f);
    const dtEnd = this.dateEnd
      .split("-")
      .reverse()
      .reduce(f);
    return axios
      .get(
        this.sgpaapiurl +
          "/telemetria/historico-disponibilidade?cd_equipamento=" +
          this.cdEquipamento +
          "&data_inicio=" +
          dtStart +
          "&data_fim=" +
          dtEnd,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token
          }
        }
      )
      .then(response => {
        this.series = [
          {
            name: "Disponibilidade",
            type: response.data.length === 1 ? "scatter" : "area",
            data: response.data.map((x: Array<string>) => x[12])
          },
          {
            name: "Média",
            type: response.data.length === 1 ? "scatter" : "line",
            data: response.data.map((x: Array<string>) => x[13])
          }
        ];
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: response.data.map((x: Array<string>) => x[6].substring(0, 5))
            }
          }
        };
      });
  }
}





































































import { Component, Vue, Prop } from "vue-property-decorator";
import { gettersEvents, mutationsEvents } from "@/store/Graphics";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import JsonCsv from "vue-json-csv";

@Component({
  components: { "download-csv": JsonCsv }
})
export default class EngineLoadProfileFilter extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() bus!: Vue;
  @Prop() token!: string;
  @Prop() sgpaapiurl!: string;
  @Prop() tpEquipamento!: number;
  /* Data */
  dateStart = new Date().toISOString().substr(0, 10);
  dateEnd = new Date().toISOString().substr(0, 10);
  dataToPdfCriticidade: { [key: string]: any }[] = [];
  dataToPdf: { [key: string]: any }[] = [];
  selectData = ["PDF", "CSV"];
  select = "PDF";
  headerToExportCsv = {
    TRPM: "RPM/Torque",
    Zero: "0",
    SevenFifty: "750",
    TwelveFifty: "1250",
    FourteenFifty: "1450",
    FifteenFifty: "1550",
    SixteenFifty: "1650",
    SeventeenFifty: "1750",
    EighteenFifty: "1850",
    NineteenFifty: "1950",
    TwentyFifty: "2050",
    TwoOneFifty: "2150",
    TwoTwoFifty: "2250",
    TwoThreeFifty: "2350",
    TwoFourFifty: "2450"
  };

  /* Methods */
  allowDates = (val: string) => new Date(val) <= new Date();

  filter() {
    mutationsEvents.setDataInicioPC(this.dateStart);
    mutationsEvents.setDataFimPC(this.dateEnd);
    axios({
      method: "post",
      url: this.sgpaapiurl + "/telemetria/perfil-carga-motor",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": this.token
      },
      data: {
        equipamento: this.cdEquipamento,
        dataInicio: this.dateStart,
        dataFim: this.dateEnd,
        tipoEquipamento: this.tpEquipamento
      }
    }).then(response => {
      mutationsEvents.setPerfilCargaDadosRawCriticidade(response.data.criticidades);
      mutationsEvents.setPerfilCargaDadosRaw(response.data.dados);
    });
  }

  getDataExportToPDF() {
    this.dataToPdfCriticidade = gettersEvents.getPerfilCargaDados().dadosCriticidade;

    const headersCriticidade: object = [
      { title: "Criticidade", dataKey: "Criticidade" },
      { title: "Horas", dataKey: "Horas" },
      { title: "%", dataKey: "Percentage" }
    ];

    const pdf: any = new jsPDF("landscape");

    const dataCriticidade = this.dataToPdfCriticidade.map((x: { [key: string]: any }) => {
      return {
        Criticidade: x.text,
        Horas: x.value,
        Percentage: x.valuePercentage,
        Tipo: x.tipo
      };
    });

    const a = pdf.autoTable(headersCriticidade, dataCriticidade, {
      startY: 0,
      startX: 20,
      didParseCell(data: { [key: string]: any }) {
        if (data.row.index === 0 && data.row.raw.Tipo === undefined) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#ffffff";
        } else if (data.row.raw.Tipo === 0) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#00b050";
        } else if (data.row.raw.Tipo === 1) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#ffc000";
        } else if (data.row.raw.Tipo === 2) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#92d050";
        } else if (data.row.raw.Tipo === 3) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#ffff00";
        } else if (data.row.raw.Tipo === 4) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#ff0000";
        } else if (data.row.raw.Tipo === 5) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#ffffff";
        } else if (data.row.raw.Tipo === null) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#bebebe";
        }
      }
    });

    this.dataToPdf = gettersEvents.getPerfilCargaDados().dados;
    const headers: object = [
      { title: "Torque/RPM", dataKey: "TRPM" },
      { title: "0", dataKey: "Zero" },
      { title: "750", dataKey: "SevenFifty" },
      { title: "1250", dataKey: "TwelveFifty" },
      { title: "1450", dataKey: "FourteenFifty" },
      { title: "1550", dataKey: "FifteenFifty" },
      { title: "1650", dataKey: "SixteenFifty" },
      { title: "1750", dataKey: "SeventeenFifty" },
      { title: "1850", dataKey: "EighteenFifty" },
      { title: "1950", dataKey: "NineteenFifty" },
      { title: "2050", dataKey: "TwentyFifty" },
      { title: "2150", dataKey: "TwoOneFifty" },
      { title: "2250", dataKey: "TwoTwoFifty" },
      { title: "2350", dataKey: "TwoThreeFifty" },
      { title: "2450", dataKey: "TwoFourFifty" }
    ];

    const data = this.dataToPdf.map((x: { [key: string]: any }) => {
      return {
        TRPM: x.torqueHeader,
        Zero: x.content0,
        SevenFifty: x.content750,
        TwelveFifty: x.content1250,
        FourteenFifty: x.content1450,
        FifteenFifty: x.content1550,
        SixteenFifty: x.content1650,
        SeventeenFifty: x.content1750,
        EighteenFifty: x.content1850,
        NineteenFifty: x.content1950,
        TwentyFifty: x.content2050,
        TwoOneFifty: x.content2150,
        TwoTwoFifty: x.content2250,
        TwoThreeFifty: x.content2350,
        TwoFourFifty: x.content2450,
        ZeroT: x.tipo0,
        SevenFiftyT: x.tipo750,
        TwelveFiftyT: x.tipo1250,
        FourteenFiftyT: x.tipo1450,
        FifteenFiftyT: x.tipo1550,
        SixteenFiftyT: x.tipo1650,
        SeventeenFiftyT: x.tipo1750,
        EighteenFiftyT: x.tipo1850,
        NineteenFiftyT: x.tipo1950,
        TwentyFiftyT: x.tipo2050,
        TwoOneFiftyT: x.tipo2150,
        TwoTwoFiftyT: x.tipo2250,
        TwoThreeFiftyT: x.tipo2350,
        TwoFourFiftyT: x.tipo2450
      };
    });
    pdf.autoTable(headers, data, {
      startY: pdf.lastAutoTable.finalY + 5,
      didParseCell(data: { [key: string]: any }) {
        if (
          (data.row.index === 0 && data.row.raw[data.column.dataKey + "T"] === undefined) ||
          data.row.raw[data.column.dataKey + "T"] === 5
        ) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#ffffff";
        } else if (data.row.raw[data.column.dataKey + "T"] === 0) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#00b050";
        } else if (data.row.raw[data.column.dataKey + "T"] === 1) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#ffc000";
        } else if (data.row.raw[data.column.dataKey + "T"] === 2) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#92d050";
        } else if (data.row.raw[data.column.dataKey + "T"] === 3) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#ffff00";
        } else if (data.row.raw[data.column.dataKey + "T"] === 4) {
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fillColor = "#ff0000";
        }
      }
    });

    const dateActual = new Date(); //.toLocaleString("pt-BR");
    const stringFileWithDate =
      "perfil-carga-motor-" +
      dateActual.getDay() +
      "" +
      dateActual.getMonth() +
      "" +
      dateActual.getFullYear() +
      "" +
      dateActual.getHours() +
      "" +
      dateActual.getMinutes() +
      "" +
      dateActual.getSeconds() +
      ".pdf";

    pdf.save(stringFileWithDate);
  }
  /* Computed */
  get filename() {
    const dateActual = new Date();
    const stringFileWithDate =
      "perfil-carga-motor-" +
      dateActual.getDay() +
      "" +
      dateActual.getMonth() +
      "" +
      dateActual.getFullYear() +
      "" +
      dateActual.getHours() +
      "" +
      dateActual.getMinutes() +
      "" +
      dateActual.getSeconds() +
      ".csv";
    return stringFileWithDate;
  }

  get dataToExportCsv() {
    let dados = gettersEvents.getPerfilCargaDados().dados;
    dados = dados.map((x: { [key: string]: any }) => {
      return {
        TRPM: x.torqueHeader,
        Zero: x.content0,
        SevenFifty: x.content750,
        TwelveFifty: x.content1250,
        FourteenFifty: x.content1450,
        FifteenFifty: x.content1550,
        SixteenFifty: x.content1650,
        SeventeenFifty: x.content1750,
        EighteenFifty: x.content1850,
        NineteenFifty: x.content1950,
        TwentyFifty: x.content2050,
        TwoOneFifty: x.content2150,
        TwoTwoFifty: x.content2250,
        TwoThreeFifty: x.content2350,
        TwoFourFifty: x.content2450
      };
    });
    return dados;
  }
}

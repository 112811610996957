






















































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from "vue-property-decorator";
import ProgressBar from "./ProgressBar.vue";
import Maintenance from "./maintenance/Maintenance.vue";
import axios from "axios";
import { gettersTelemetria, mutationsTelemetria } from "@/store/Telemetria";
@Component({
  components: {
    ProgressBar,
    Maintenance
  }
})
export default class Equipments extends Vue {
  /*Data*/
  search = "";
  dialog = false;
  equipment: { [key: string]: any } = [];
  token = gettersTelemetria.getToken();
  frentes = [
    {
      frente: "",
      equipamentos: [],
      countAtivosDisponivel: 0,
      countAtivos9: 0,
      countAtivos10: 0,
      countAtivosEmAtendimento: 0,
      countAtivosIndisponivel: 0,
      countInativosDisponivel: 0,
      countInativosEmAtendimento: 0,
      countInativosIndisponivel: 0,
      countInativos9: 0,
      countInativos10: 0,
      porcentagemDisponiveis: 0,
      porcentagemEmAtendimento: 0,
      porcentagemIndisponiveis: 0,
      totalCountRepeated: 0,
      totalCount: 0
    }
  ];
  filterFail = false;
  show = true;
  alarmListObj: any[] = [];

  /*Computed*/
  get color() {
    return gettersTelemetria.getFalhasT();
  }

  get alarmes() {
    return gettersTelemetria.getAlarmes();
  }

  get equipamentosAtivos() {
    const equipments: {
      [key: string]: any;
    }[] = gettersTelemetria.getMonitoringOnline();
    const alarms = this.alarmes;
    for (let indexEquipamento = 0; indexEquipamento < equipments.length; indexEquipamento++) {
      let flag = false;
      for (let index = 0; index < alarms.length; index++) {
        if (alarms[index].cdEquipamento === equipments[indexEquipamento].cdEquipamento) {
          flag = true;
          if (alarms[index].fgClassificacao === 9) {
            if (
              equipments[indexEquipamento].fgClassificacao === 10 ||
              equipments[indexEquipamento].fgClassificacao === "9e10"
            ) {
              equipments[indexEquipamento].fgClassificacao = "9e10";
            } else {
              equipments[indexEquipamento].fgClassificacao = alarms[index].fgClassificacao;
            }
          } else if (alarms[index].fgClassificacao === 10) {
            if (
              equipments[indexEquipamento].fgClassificacao === 9 ||
              equipments[indexEquipamento].fgClassificacao === "9e10"
            ) {
              equipments[indexEquipamento].fgClassificacao = "9e10";
            } else {
              equipments[indexEquipamento].fgClassificacao = alarms[index].fgClassificacao;
            }
          } else {
            if (
              equipments[indexEquipamento].fgClassificacao === 9 ||
              equipments[indexEquipamento].fgClassificacao === 10 ||
              equipments[indexEquipamento].fgClassificacao === "9e10"
            ) {
              //pass
            } else {
              equipments[indexEquipamento].fgClassificacao = alarms[index].fgClassificacao;
            }
          }
        }
      }
      if (!flag) {
        equipments[indexEquipamento].fgClassificacao = 0;
      }
    }
    return equipments;
  }
  get equipamentosInativos() {
    const equipments: {
      [key: string]: any;
    }[] = gettersTelemetria.getMonitoringOffline();
    const alarms = this.alarmes;
    for (let indexEquipamento = 0; indexEquipamento < equipments.length; indexEquipamento++) {
      let flag = false;
      for (let index = 0; index < alarms.length; index++) {
        if (alarms[index].cdEquipamento === equipments[indexEquipamento].cdEquipamento) {
          flag = true;
          if (alarms[index].fgClassificacao === 9) {
            if (
              equipments[indexEquipamento].fgClassificacao === 10 ||
              equipments[indexEquipamento].fgClassificacao === "9e10"
            ) {
              equipments[indexEquipamento].fgClassificacao = "9e10";
            } else {
              equipments[indexEquipamento].fgClassificacao = alarms[index].fgClassificacao;
            }
          } else if (alarms[index].fgClassificacao === 10) {
            if (
              equipments[indexEquipamento].fgClassificacao === 9 ||
              equipments[indexEquipamento].fgClassificacao === "9e10"
            ) {
              equipments[indexEquipamento].fgClassificacao = "9e10";
            } else {
              equipments[indexEquipamento].fgClassificacao = alarms[index].fgClassificacao;
            }
          } else {
            if (
              equipments[indexEquipamento].fgClassificacao === 9 ||
              equipments[indexEquipamento].fgClassificacao === 10 ||
              equipments[indexEquipamento].fgClassificacao === "9e10"
            ) {
              //pass
            } else {
              equipments[indexEquipamento].fgClassificacao = alarms[index].fgClassificacao;
            }
          }
        }
      }
      if (!flag) {
        equipments[indexEquipamento].fgClassificacao = 0;
      }
    }

    return equipments;
  }
  /*Methods*/

  openModal(item: { [key: string]: any }) {
    this.dialog = true;
    this.equipment = item;
  }

  resolveColor(cdEquipamento: string) {
    let color = "#585858";
    for (let index = 0; index < this.color.length; index++) {
      if (this.color[index].id === cdEquipamento) {
        if (this.color[index].cor === "#f92121") {
          color = this.color[index].cor;
        } else if (this.color[index].cor === "#ff7e22") {
          if (color != "#f92121") {
            color = this.color[index].cor;
          } else if (this.color[index].cor === "#ffc107") {
            if (color != "#f92121" && color != "#ff7e22") {
              color = this.color[index].cor;
            }
          } else if (this.color[index].cor === "#0a16f2") {
            if (color != "#f92121" && color != "#ff7e22" && color != "#ffc107") {
              color = this.color[index].cor;
            }
          }
        }
      }
    }
    return color;
  }

  onClickFilter() {
    if (this.search) {
      const equipamentos: { [key: string]: any }[] = this.frentes.map(x => x.equipamentos);
      let indexEquipAux;
      let indexEquip = -1;
      const indexFrente = equipamentos.findIndex(y => {
        indexEquipAux = y.findIndex((z: any) => z.cdEquipamento === this.search);
        if (indexEquipAux != -1) {
          indexEquip = indexEquipAux;
        }
        return indexEquipAux != -1;
      });
      if (indexFrente != -1) {
        this.filterFail = false;
        this.frentes = [this.frentes[indexFrente]];
        const equip = this.frentes[0].equipamentos.splice(indexEquip, 1);
        this.frentes[0].equipamentos.unshift(equip[0]);
      } else {
        this.filterFail = true;
      }
    } else {
      this.filterFail = false;
      this.loadFrentes();
    }
  }

  passListAlarm(pAlarmList: any[]) {
    this.alarmListObj = pAlarmList;
  }

  urlIcons(url: string): string {
    return process.env.VUE_APP_CDN_BASE_URL + url;
  }

  changeShow() {
    this.show = !this.show;
  }

  loadFrentes() {
    if (this.equipamentosAtivos.concat(this.equipamentosInativos).length !== 0) {
      axios({
        method: "post",
        url: gettersTelemetria.getUrlSgpa() + "/telemetria/lista-equipamentos-por-frente",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": this.token
        },
        data: {
          equipamentos: this.equipamentosAtivos
            .concat(this.equipamentosInativos)
            .map(x => x.cdEquipamento)
        }
      }).then((response: any) => {
        let countFinal9 = 0;
        let countFinal10 = 0;
        let countFinalM = 0;
        let countFinalD = 0;
        this.frentes = response.data.map((x: { [key: string]: any }): { [key: string]: any } => {
          const equipAtivos9 = [];
          const equipAtivos10 = [];
          const equipAtivosM = [];
          const equipAtivos = [];
          const equipInativos9 = [];
          const equipInativos10 = [];
          const equipInativosM = [];
          const equipInativos = [];
          const data = x.equipamentos;
          let countAtivoEmAtendimento = 0;
          let countAtivoIndisponivel = 0;
          let countAtivoDisponivel = 0;
          let countInativoEmAtendimento = 0;
          let countInativoIndisponivel = 0;
          let countInativoDisponivel = 0;
          let countAtivo9 = 0;
          let countAtivo10 = 0;
          let countInativo9 = 0;
          let countInativo10 = 0;
          for (let i = 0; i < data.length; i++) {
            const indexAtivo = this.equipamentosAtivos.findIndex(z => z.cdEquipamento === data[i]);
            const indexInativo = this.equipamentosInativos.findIndex(
              z => z.cdEquipamento === data[i]
            );
            if (indexAtivo != -1) {
              if (this.equipamentosAtivos[indexAtivo].fgClassificacao === 10) {
                countAtivo10 += 1;
                equipAtivos10.push(this.equipamentosAtivos[indexAtivo]);
                countAtivoIndisponivel += 1;
                if (this.equipamentosAtivos[indexAtivo].fgTipoOperac === "M") {
                  countAtivoEmAtendimento += 1;
                }
              } else if (this.equipamentosAtivos[indexAtivo].fgClassificacao === 9) {
                countAtivo9 += 1;
                equipAtivos9.push(this.equipamentosAtivos[indexAtivo]);
                countAtivoIndisponivel += 1;
                if (this.equipamentosAtivos[indexAtivo].fgTipoOperac === "M") {
                  countAtivoEmAtendimento += 1;
                }
              } else if (this.equipamentosAtivos[indexAtivo].fgClassificacao === "9e10") {
                countAtivo9 += 1;
                countAtivo10 += 1;
                countAtivoIndisponivel += 1;
                equipAtivos10.push(this.equipamentosAtivos[indexAtivo]);
                if (this.equipamentosAtivos[indexAtivo].fgTipoOperac === "M") {
                  countAtivoEmAtendimento += 1;
                }
              } else {
                if (this.equipamentosAtivos[indexAtivo].fgTipoOperac === "M") {
                  countAtivoEmAtendimento += 1;
                  equipAtivosM.push(this.equipamentosAtivos[indexAtivo]);
                } else {
                  equipAtivos.push(this.equipamentosAtivos[indexAtivo]);
                  countAtivoDisponivel += 1;
                }
              }
            }
            if (indexInativo != -1) {
              if (this.equipamentosInativos[indexInativo].fgClassificacao === 10) {
                countInativo10 += 1;
                equipInativos10.push(this.equipamentosInativos[indexInativo]);
                countInativoIndisponivel += 1;
                if (this.equipamentosInativos[indexInativo].fgTipoOperac === "M") {
                  countInativoEmAtendimento += 1;
                }
              } else if (this.equipamentosInativos[indexInativo].fgClassificacao === 9) {
                countInativo9 += 1;
                equipInativos9.push(this.equipamentosInativos[indexInativo]);
                countInativoIndisponivel += 1;
                if (this.equipamentosInativos[indexInativo].fgTipoOperac === "M") {
                  countInativoEmAtendimento += 1;
                }
              } else if (this.equipamentosInativos[indexInativo].fgClassificacao === "9e10") {
                countInativo9 += 1;
                countInativo10 += 1;
                countInativoIndisponivel += 1;
                if (this.equipamentosInativos[indexInativo].fgTipoOperac === "M") {
                  countInativoEmAtendimento += 1;
                }
                equipInativos10.push(this.equipamentosInativos[indexInativo]);
              } else {
                if (this.equipamentosInativos[indexInativo].fgTipoOperac === "M") {
                  countInativoEmAtendimento += 1;
                  equipInativosM.push(this.equipamentosInativos[indexInativo]);
                } else {
                  equipInativos.push(this.equipamentosInativos[indexInativo]);
                  countInativoDisponivel += 1;
                }
              }
            }
          }
          const ttCountRepeated =
            countAtivoDisponivel +
            countAtivoEmAtendimento +
            countAtivoIndisponivel +
            countInativoDisponivel +
            countInativoEmAtendimento +
            countInativoIndisponivel;
          let pcDisponivel = Math.round(
            ((countAtivoDisponivel + countInativoDisponivel) / ttCountRepeated) * 100
          );
          let pcEmAtendimento = Math.round(
            ((countAtivoEmAtendimento + countInativoEmAtendimento) / ttCountRepeated) * 100
          );
          let pcIndisponivel = Math.round(
            ((countAtivoIndisponivel + countInativoIndisponivel) / ttCountRepeated) * 100
          );
          if (pcDisponivel + pcEmAtendimento + pcIndisponivel > 100) {
            if (pcDisponivel != 0) {
              pcDisponivel -= 1;
            } else if (pcEmAtendimento != 0) {
              pcEmAtendimento -= 1;
            } else if (pcIndisponivel != 0) {
              pcIndisponivel -= 1;
            } else if (pcDisponivel + pcEmAtendimento + pcIndisponivel < 100) {
              if (pcDisponivel != 0) {
                pcDisponivel = pcDisponivel + 1;
              } else if (pcEmAtendimento != 0) {
                pcEmAtendimento = pcEmAtendimento + 1;
              } else if (pcIndisponivel != 0) {
                pcIndisponivel = pcIndisponivel + 1;
              }
            }
          }
          countFinal9 = countFinal9 + countAtivo9 + countInativo9;
          countFinal10 = countFinal10 + countAtivo10 + countInativo10;
          countFinalM = countFinalM + countAtivoEmAtendimento + countInativoEmAtendimento;
          countFinalD = countFinalD + countAtivoDisponivel + countInativoDisponivel;
          return {
            frente: x.frente,
            equipamentos: equipAtivos9
              .concat(equipAtivos10)
              .concat(equipAtivosM)
              .concat(equipAtivos)
              .concat(equipInativos9)
              .concat(equipInativos10)
              .concat(equipInativosM)
              .concat(equipInativos),
            countAtivos9: countAtivo9,
            countAtivos10: countAtivo10,
            countAtivosDisponivel: countAtivoDisponivel,
            countAtivosIndisponivel: countAtivoIndisponivel,
            countAtivosEmAtendimento: countAtivoEmAtendimento,
            countInativos9: countInativo9,
            countInativos10: countInativo10,
            countInativosDisponivel: countInativoDisponivel,
            countInativosIndisponivel: countInativoIndisponivel,
            countInativosEmAtendimento: countInativoEmAtendimento,
            porcentagemDisponiveis: pcDisponivel,
            porcentagemEmAtendimento: pcEmAtendimento,
            porcentagemIndisponiveis: pcIndisponivel,
            totalCountFooter:
              countAtivo9 +
              countAtivo10 +
              countAtivoEmAtendimento +
              countAtivoDisponivel +
              countInativo9 +
              countInativo10 +
              countInativoEmAtendimento +
              countInativoDisponivel,
            totalCountRepeated: ttCountRepeated,
            totalCount: data.length
          };
        });
        mutationsTelemetria.setCounts({
          count9: countFinal9,
          count10: countFinal10,
          countM: countFinalM,
          countD: countFinalD,
          countTotal: countFinal9 + countFinal10 + countFinalM + countFinalD
        });
        this.show = true;
      });
    }
  }

  /*Watch*/
  @Watch("equipamentosAtivos") onEquipamentosAtivos(): void {
    this.loadFrentes();
  }

  @Watch("alarmes") onAlarmes(): void {
    this.loadFrentes();
  }
}























/* eslint-disable @typescript-eslint/no-explicit-any */
import { Prop, Vue, Component } from "vue-property-decorator";
import { gettersEvents } from "@/store/Graphics";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({})
export default class GraphicsHistoricalFailures extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() token!: string;
  @Prop() sgpaapiurl!: string;
  @Prop() bus!: Vue;
  /* data */
  headers = [
    {
      text: "Data",
      align: "start",
      sortable: false,
      value: "data"
    },
    { text: "Hora", value: "hora" },
    { text: "Criticidade", value: "criticidade" },
    { text: "Horímetro", value: "horimetro" },
    { text: "Operador", value: "operador" },
    { text: "Código do Alarme", value: "codAlarme" },
    { text: "Descrição", value: "descricao" },
    { text: "Duração", value: "duracao" }
  ];
  listHistoricoFalhas = [];
  data = [];
  gravidade = 0;
  dataHistoricoFalhasToPdf: Array<object> = [];

  /* methods */
  getHistoricoFalhas() {
    const f = (accumulator: string, currentValue: string) => accumulator + currentValue;
    const dtStart = this.dataStart
      .split("-")
      .reverse()
      .reduce(f);
    const dtEnd = this.dataEnd
      .split("-")
      .reverse()
      .reduce(f);

    axios
      .get(
        this.sgpaapiurl +
          "/telemetria/historico-falha?cd_equipamento=" +
          this.cdEquipamento +
          "&data_inicio=" +
          dtStart +
          "&data_fim=" +
          dtEnd +
          "&criticidade=" +
          this.criticidade +
          "&operador=" +
          this.operador +
          "&spn=" +
          this.spn,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token
          }
        }
      )
      .then(response => {
        let index = 0;
        while (index != -1) {
          // debugger;
          index = response.data
            .map((x: Array<object>) => x[6])
            .findIndex((x: string) => x === "Não cadastrado");
          if (index != -1) {
            response.data.splice(index, 1);
          }
        }
        this.data = response.data.map((x: Array<object>) => {
          return {
            data: x[0],
            hora: x[1],
            criticidade: x[2],
            horimetro: x[3],
            operador: x[4],
            codAlarme: x[5],
            descricao: x[6],
            duracao: x[7]
          };
        });

        //Retornando Dados para exportação PDF
        Object.assign(this.listHistoricoFalhas, this.data);
      });
  }
  getDataExportToPDF() {
    this.dataHistoricoFalhasToPdf.push(this.listHistoricoFalhas);

    const headers: object = [
      { title: "Data", dataKey: "Data" },
      { title: "Hora", dataKey: "Hora" },
      { title: "Criticidade", dataKey: "Criticidade" },
      { title: "Horímetro", dataKey: "Horímetro" },
      { title: "Operador", dataKey: "Operador" },
      { title: "Código", dataKey: "Código" },
      { title: "Descrição", dataKey: "Descrição" },
      { title: "Duração", dataKey: "Duração" }
    ];

    this.dataHistoricoFalhasToPdf.push(headers);
    const dataToExport: any = this.dataHistoricoFalhasToPdf;
    const pdf: any = new jsPDF("landscape");
    let header: object = [];
    header = dataToExport[1];

    let dados: Array<Global.DataToExport> = [];
    dados = dataToExport[0];
    const data = dados.map((x: Global.DataToExport) => {
      return {
        Data: x.data,
        Hora: x.hora,
        Criticidade: x.criticidade,
        Horímetro: x.horimetro,
        Operador: x.operador,
        Código: x.codAlarme,
        Descrição: x.descricao,
        Duração: x.duracao
      };
    });

    pdf.text("Histórico de Falhas", 30, 20);
    pdf.autoTable(header, data, {
      startY: 25,
      startX: 20
    });

    const dateActual = new Date(); //.toLocaleString("pt-BR");
    const stringFileWithDate =
      "historico-de-falhas-" +
      dateActual.getDay() +
      "" +
      dateActual.getMonth() +
      "" +
      dateActual.getFullYear() +
      "" +
      dateActual.getHours() +
      "" +
      dateActual.getMinutes() +
      "" +
      dateActual.getSeconds() +
      ".pdf";

    pdf.save(stringFileWithDate);
  }

  /* hooks */

  created() {
    this.bus.$on("filterChild", () => {
      this.getHistoricoFalhas();
    });
  }

  /* computed */
  get dataStart() {
    return gettersEvents.getFilterFailures().dateStart;
  }
  get dataEnd() {
    return gettersEvents.getFilterFailures().dateEnd;
  }
  get criticidade() {
    return gettersEvents.getFilterFailures().criticidade;
  }
  get operador() {
    return gettersEvents.getFilterFailures().operador;
  }
  get spn() {
    return gettersEvents.getFilterFailures().spn;
  }
}


















import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { gettersEvents, mutationsEvents } from "@/store/Graphics";
import IEcharts from "vue-echarts-v3/src/lite.js";

import "echarts/lib/chart/gauge";
import axios from "axios";

@Component({
  components: {
    IEcharts
  }
})
export default class GraphicsPanelTemperature extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() sgpaapiurl!: string;
  @Prop() token!: string;
  @Prop() bus!: Vue;
  @Prop() modeloEquipamento!: number;
  /* data */
  painelTemperaturaPressao = [
    {
      id: 0,
      nomeGrafico: this.parametroUm,
      data: [{ value: 0 }]
    },
    {
      id: 1,
      nomeGrafico: this.parametroDois,
      data: [{ value: 0 }]
    },
    {
      id: 2,
      nomeGrafico: this.parametroTres,
      data: [{ value: 0 }]
    },
    {
      id: 3,
      nomeGrafico: this.parametroQuatro,
      data: [{ value: 0 }]
    }
  ];
  /* computed */
  get parametroUm() {
    return gettersEvents.getValoresGraficoPainel().parametroUm;
  }
  get parametroDois() {
    return gettersEvents.getValoresGraficoPainel().parametroDois;
  }
  get parametroTres() {
    return gettersEvents.getValoresGraficoPainel().parametroTres;
  }
  get parametroQuatro() {
    return gettersEvents.getValoresGraficoPainel().parametroQuatro;
  }
  get parametros() {
    return gettersEvents.getFilterSensors().parametros;
  }
  get dataInicio() {
    return gettersEvents.getFilterSensors().dataInicio;
  }
  get dataFim() {
    return gettersEvents.getFilterSensors().dataFim;
  }
  get gauges() {
    const indexes = [];
    const min = [];
    const max = [];
    const medidas = [];
    for (let i = 0; i < 4; i++) {
      indexes.push(
        gettersEvents
          .getMinMaxPainel()
          .map(x => x.tipo)
          .findIndex(x => x === this.painelTemperaturaPressao[i].nomeGrafico)
      );
    }
    min.push(gettersEvents.getValoresGraficoPainel().minParametroUm);
    min.push(gettersEvents.getValoresGraficoPainel().minParametroDois);
    min.push(gettersEvents.getValoresGraficoPainel().minParametroTres);
    min.push(gettersEvents.getValoresGraficoPainel().minParametroQuatro);
    max.push(gettersEvents.getValoresGraficoPainel().maxParametroUm);
    max.push(gettersEvents.getValoresGraficoPainel().maxParametroDois);
    max.push(gettersEvents.getValoresGraficoPainel().maxParametroTres);
    max.push(gettersEvents.getValoresGraficoPainel().maxParametroQuatro);
    medidas.push(gettersEvents.getValoresGraficoPainel().medidaParametroUm);
    medidas.push(gettersEvents.getValoresGraficoPainel().medidaParametroDois);
    medidas.push(gettersEvents.getValoresGraficoPainel().medidaParametroTres);
    medidas.push(gettersEvents.getValoresGraficoPainel().medidaParametroQuatro);
    const a = [
      {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}"
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            restore: { show: false },
            saveAsImage: { show: false }
          }
        },
        series: [
          {
            title: {
              show: true,
              offsetCenter: [0, -230],
              color: "#888",
              fontWeight: "bold",
              fontSize: 24
            },
            name: this.painelTemperaturaPressao[0].nomeGrafico,
            type: "gauge",
            radius: "80%",
            min: min[0],
            max: max[0],
            detail:
              !isNaN(this.painelTemperaturaPressao[0].data[0].value) &&
              this.painelTemperaturaPressao[0].data[0].value !== -1
                ? { formatter: "{value}" + medidas[0] }
                : { formatter: "Sem dados" },
            startAngle: 180,
            splitNumber: 5,
            endAngle: 0,
            data: this.painelTemperaturaPressao[0].data
          }
        ]
      },
      {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}"
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            restore: { show: false },
            saveAsImage: { show: false }
          }
        },
        series: [
          {
            title: {
              show: true,
              offsetCenter: [0, -230],
              color: "#888",
              fontWeight: "bold",
              fontSize: 24
            },
            name: this.painelTemperaturaPressao[1].nomeGrafico,
            type: "gauge",
            radius: "80%",
            min: min[1],
            max: max[1],
            splitNumber: 5,
            detail:
              !isNaN(this.painelTemperaturaPressao[1].data[0].value) &&
              this.painelTemperaturaPressao[1].data[0].value !== -1
                ? { formatter: "{value} " + medidas[1] }
                : { formatter: "Sem dados" },
            startAngle: 180,
            endAngle: 0,
            data: this.painelTemperaturaPressao[1].data
          }
        ]
      },
      {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}"
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            restore: { show: false },
            saveAsImage: { show: false }
          }
        },
        series: [
          {
            title: {
              show: true,
              offsetCenter: [0, -230],
              color: "#888",
              fontWeight: "bold",
              fontSize: 24
            },
            name: this.painelTemperaturaPressao[2].nomeGrafico,
            type: "gauge",
            radius: "80%",
            min: min[2],
            max: max[2],
            splitNumber: 5,
            detail:
              !isNaN(this.painelTemperaturaPressao[2].data[0].value) &&
              this.painelTemperaturaPressao[2].data[0].value !== -1
                ? { formatter: "{value} " + medidas[2] }
                : { formatter: "Sem dados" },
            startAngle: 180,
            endAngle: 0,
            data: this.painelTemperaturaPressao[2].data
          }
        ]
      },
      {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}"
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            restore: { show: false },
            saveAsImage: { show: false }
          }
        },
        series: [
          {
            title: {
              show: true,
              offsetCenter: [0, -230],
              color: "#888",
              fontWeight: "bold",
              fontSize: 24
            },
            name: this.painelTemperaturaPressao[3].nomeGrafico,
            type: "gauge",
            radius: "80%",
            min: min[3],
            max: max[3],
            splitNumber: 5,
            detail:
              !isNaN(this.painelTemperaturaPressao[3].data[0].value) &&
              this.painelTemperaturaPressao[3].data[0].value !== -1
                ? { formatter: "{value} " + medidas[3] }
                : { formatter: "Sem dados" },
            startAngle: 180,
            endAngle: 0,
            data: this.painelTemperaturaPressao[3].data
          }
        ]
      }
    ];
    return a;
  }
  /* methods */
  created() {
    this.bus.$on("filterChild", () => {
      this.getPainelTemperaturaPressao();
    });
  }

  getListaMinMax(dataParametros: { [key: string]: any }[]) {
    axios
      .get(
        this.sgpaapiurl +
          "/parametrosRedecan/findByModel?cdModeloEquipamento=" +
          this.modeloEquipamento,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token
          }
        }
      )
      .then(response => {
        const data = response.data;
        if (dataParametros[0].parametro !== "Nao cadastrado") {
          const campo = gettersEvents
            .getMinMaxPainel()
            .filter(x => x.tipo === dataParametros[0].parametro)[0].campo;
          if (campo) {
            const campoT = campo
              .toLowerCase()
              .split("_")
              .join(" ")
              .replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
            mutationsEvents.setMinParametroUm(data[campoT + "Minimo"]);
            mutationsEvents.setMaxParametroUm(data[campoT + "Maximo"]);
          }
        }
        if (dataParametros[1].parametro) {
          const campo = gettersEvents
            .getMinMaxPainel()
            .filter(x => x.tipo === dataParametros[1].parametro)[0].campo;
          if (campo) {
            const campoT = campo
              .toLowerCase()
              .split("_")
              .join(" ")
              .replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
            mutationsEvents.setMinParametroDois(data[campoT + "Minimo"]);
            mutationsEvents.setMaxParametroDois(data[campoT + "Maximo"]);
          }
        }
        if (dataParametros[2].parametro) {
          const campo = gettersEvents
            .getMinMaxPainel()
            .filter(x => x.tipo === dataParametros[2].parametro)[0].campo;
          if (campo) {
            const campoT = campo
              .toLowerCase()
              .split("_")
              .join(" ")
              .replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
            mutationsEvents.setMinParametroTres(data[campoT + "Minimo"]);
            mutationsEvents.setMaxParametroTres(data[campoT + "Maximo"]);
          }
        }
        if (dataParametros[3].parametro) {
          const campo = gettersEvents
            .getMinMaxPainel()
            .filter(x => x.tipo === dataParametros[3].parametro)[0].campo;
          if (campo) {
            const campoT = campo
              .toLowerCase()
              .split("_")
              .join(" ")
              .replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
            mutationsEvents.setMinParametroQuatro(data[campoT + "Minimo"]);
            mutationsEvents.setMaxParametroQuatro(data[campoT + "Maximo"]);
          }
        }
      });
  }

  getPainelTemperaturaPressao() {
    const parametrosTemp = [];
    for (let i = 0; i < 4; i++) {
      if (this.parametros[i] === undefined) {
        parametrosTemp.push("");
      } else {
        parametrosTemp.push(this.parametros[i]);
      }
    }
    axios({
      method: "post",
      url: this.sgpaapiurl + "/telemetria/painel-temperatura-pressao",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": this.token
      },
      data: {
        equipamento: this.cdEquipamento,
        parametros: parametrosTemp,
        dataInicio: this.dataInicio,
        dataFim: this.dataFim
      }
    }).then(response => {
      const data = response.data;
      this.painelTemperaturaPressao[0].data = [
        {
          value: data[0].valor
        }
      ];

      this.painelTemperaturaPressao[0].nomeGrafico = data[0].parametro;

      if (data[0].parametro !== "Nao cadastrado") {
        mutationsEvents.setMedidaParametroUm(
          gettersEvents.getMinMaxPainel().filter(x => x.tipo === data[0].parametro)[0].medida
        );
      } else {
        mutationsEvents.setMinParametroUm(0);
        mutationsEvents.setMaxParametroUm(0);
        mutationsEvents.setMedidaParametroUm("");
      }

      this.painelTemperaturaPressao[1].data = [
        {
          value: data[1].valor
        }
      ];

      this.painelTemperaturaPressao[1].nomeGrafico = response.data[1].parametro;
      if (data[1].parametro !== "Nao cadastrado") {
        mutationsEvents.setMedidaParametroDois(
          gettersEvents.getMinMaxPainel().filter(x => x.tipo === data[1].parametro)[0].medida
        );
      } else {
        mutationsEvents.setMinParametroDois(0);
        mutationsEvents.setMaxParametroDois(0);
        mutationsEvents.setMedidaParametroDois("");
      }

      this.painelTemperaturaPressao[2].data = [
        {
          value: data[2].valor
        }
      ];

      this.painelTemperaturaPressao[2].nomeGrafico = response.data[2].parametro;
      if (data[2].parametro !== "Nao cadastrado") {
        mutationsEvents.setMedidaParametroTres(
          gettersEvents.getMinMaxPainel().filter(x => x.tipo === data[2].parametro)[0].medida
        );
      } else {
        mutationsEvents.setMinParametroTres(0);
        mutationsEvents.setMaxParametroTres(0);
        mutationsEvents.setMedidaParametroTres("");
      }
      this.painelTemperaturaPressao[3].data = [
        {
          value: data[3].valor
        }
      ];

      this.painelTemperaturaPressao[3].nomeGrafico = response.data[3].parametro;

      if (data[3].parametro !== "Nao cadastrado") {
        mutationsEvents.setMedidaParametroQuatro(
          gettersEvents.getMinMaxPainel().filter(x => x.tipo === data[3].parametro)[0].medida
        );
      } else {
        mutationsEvents.setMinParametroQuatro(0);
        mutationsEvents.setMaxParametroQuatro(0);
        mutationsEvents.setMedidaParametroQuatro("");
      }
      this.getListaMinMax(data);
    });
  }
}

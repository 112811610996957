































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { gettersTelemetria } from "@/store/Telemetria";
import axios from "axios";

@Component({})
export default class FailureDelete extends Vue {
  @Prop() failureSelected!:Failures.FailureLevel

  alert = false;
  message = "";
  color = "";
  dataDisplay !: Failures.FailureLevel;

  created(){
    this.dataDisplay = {
      ...this.failureSelected
    }
  }

  /* Methods */

  confirm() {
    this.deleteCodigoFalha();
  }
  close() {
    this.$emit("close");
  }
  deleteCodigoFalha() {
  axios
    .delete(
      gettersTelemetria.getUrlSgpa() + "/telemetria/codigos-falha/deletar/" +
      this.failureSelected.cdId,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": gettersTelemetria.getToken()
        }
      }
    )
    .then(response => {
      if (response.status == 200) {
        this.close();
        this.$emit("updateFalhasList", "deletado");
      } else {
        console.log(response);
        this.setAlert();
      }
    })
    .catch(error => {
      console.log("ERROR:", error);
      this.setAlert();
    });
  }
  setAlert() {
    this.alert = true;
    this.message = "Erro ao deletar código de falha!";
    this.color = "error";
  }
}
































































import { Component, Prop, Vue } from "vue-property-decorator";
import { gettersEvents } from "@/store/Graphics";
import axios from "axios";

@Component({})
export default class GraphicsRankingRecurrence extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() sgpaapiurl!: string;
  @Prop() token!: string;
  @Prop() bus!: Vue;

  /* data */
  list = [];
  ordenated = false;

  /* hooks */
  created() {
    this.bus.$on("filterChild", () => {
      this.getRankingRecorrencia();
    });
  }

  /* methods */
  ordenar() {
    this.ordenated = !this.ordenated;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.list.sort((a: { [key: string]: any }, b: { [key: string]: any }) =>
      this.ordenated ? a.ocorrencia - b.ocorrencia : b.ocorrencia - a.ocorrencia
    );
  }
  getRankingRecorrencia() {
    const f = (accumulator: string, currentValue: string) => accumulator + currentValue;
    const dtStart = this.dataStart
      .split("-")
      .reverse()
      .reduce(f);
    const dtEnd = this.dataEnd
      .split("-")
      .reverse()
      .reduce(f);
    axios
      .get(
        this.sgpaapiurl +
          "/telemetria/ranking-recorrencia?cd_equipamento=" +
          this.cdEquipamento +
          "&data_inicio=" +
          dtStart +
          "&data_fim=" +
          dtEnd +
          "&criticidade=" +
          this.criticidadeFiltro +
          "&operador=" +
          this.operador +
          "&spn=" +
          this.spn,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token
          }
        }
      )
      .then(response => {
        const index = response.data
          .map((x: Array<object>) => x[0])
          .findIndex((x: string) => x === "Não cadastrado");
        if (index != -1) {
          response.data.splice(index, 1);
        }
        response.data.sort((a: [string, number, string], b: [string, number, string]) =>
          this.ordenated ? a[1] - b[1] : b[1] - a[1]
        );
        this.list = response.data.map((x: Array<object>) => {
          return { descricao: x[0], ocorrencia: x[1], criticidade: x[2] };
        });
      });
  }

  /* computed */
  get dataStart() {
    return gettersEvents.getFilterFailures().dateStart;
  }
  get dataEnd() {
    return gettersEvents.getFilterFailures().dateEnd;
  }
  get criticidadeFiltro() {
    return gettersEvents.getFilterFailures().criticidade;
  }
  get operador() {
    return gettersEvents.getFilterFailures().operador;
  }
  get spn() {
    return gettersEvents.getFilterFailures().spn;
  }
}
















import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import { gettersEvents, mutationsEvents } from "@/store/Graphics";
@Component({
  components: {}
})
export default class EngineLoadProfileList extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() bus!: Vue;
  @Prop() token!: string;
  @Prop() sgpaapiurl!: string;
  @Prop() tpEquipamento!: number;
  /* Data */

  engineLoad = {};
  /* Computed */

  get dados() {
    return gettersEvents.getPerfilCargaDadosRaw().dadosCriticidade;
  }

  get headers() {
    return [{ text: "Criticidade" }, { text: "Horas" }, { text: "%" }];
  }

  get tableData() {
    const a: { [key: string]: any }[] = [];
    if (this.dados.length !== 0) {
      for (let i = 0; i < this.dados.length; i++) {
        a.push({
          text: this.dados[i].nome,
          value: this.dados[i].totalHoras,
          valuePercentage: this.dados[i].porcentagemHoras,
          tipo: this.dados[i].tipo
        });
      }
    }
    mutationsEvents.setPerfilCargaDadosCriticidade(a);
    return a;
  }

  /* Computed */

  get dataInicio() {
    return gettersEvents.getPerfilCarga().dataInicio;
  }

  get dataFim() {
    return gettersEvents.getPerfilCarga().dataFim;
  }

  /* Methods */
  getItemColor(item: { [key: string]: any }) {
    return item.tipo !== null ? "style" + item.tipo : "style";
  }

  // mounted() {
  //   this.getEngineLoadProfileList();
  // }
}

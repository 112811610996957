




































































































import { Vue, Component } from "vue-property-decorator";
import { mutationsEvents } from "@/store/Graphics";

@Component({})
export default class MenuFailures extends Vue {
  /* data */
  dateStart = new Date().toISOString().substr(0, 10);
  dateEnd = new Date().toISOString().substr(0, 10);
  operador = "";
  spn = "";
  criticidade = 0;
  criticidadeList = [
    { id: 0, idNivel: -1, desc: "Tudo" },
    { id: 1, idNivel: 83, desc: "Erro" },
    { id: 2, idNivel: 82, desc: "Alerta" },
    { id: 3, idNivel: 81, desc: "Alarme" },
    { id: 4, idNivel: 1, desc: "Crítico" }
  ];

  /* Methods */
  allowDates = (val: string) => new Date(val) <= new Date();

  filter() {
    mutationsEvents.setDateStart(this.dateStart);
    mutationsEvents.setDateEnd(this.dateEnd);
    mutationsEvents.setCriticidade(
      this.criticidadeList.filter(x => x.id === this.criticidade)[0].idNivel
    );
    mutationsEvents.setOperador(this.operador);
    mutationsEvents.setSpn(this.spn.toString());
    this.$emit("filter", "filter");
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",staticStyle:{"font-size":"medium !important"},attrs:{"primary-title":""}},[_vm._v(" Configuração e Cadastros de Código de Falha "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3 ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Nova Falha")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"font-size":"medium"},attrs:{"headers":_vm.headers,"height":"55vh","items":_vm.data,"item-key":"id","loading":_vm.loading,"search":_vm.search,"hide-default-footer":false,"items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-options': _vm.rowsPerPageItems
      }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"color":"blue"}})],1)]},proxy:true},{key:"item.cdCriticidadeCliente.descNivelAlarme",fn:function(ref){
      var item = ref.item;
return [_c('v-container',{attrs:{"bg":"","fill-height":"","grid-list-md":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{attrs:{"md2":""}},[_c('div',{style:({
                  'background-color':
                    item.cdCriticidadeCliente === null
                      ? '#808080'
                      : item.cdCriticidadeCliente.vlGrafico,
                  height: '25px',
                  width: '25px',
                  cursor: 'pointer',
                  'border-radius': '50%',
                  display: 'inline-block'
                })})]),_c('v-flex',{attrs:{"md5":""}},[_c('v-select',{attrs:{"items":_vm.criticalidadeList,"item-key":"cdNivelAlarme","item-value":"cdNivelAlarme","item-text":"descNivelAlarme","value":item.cdCriticidadeCliente !== null
                    ? item.cdCriticidadeCliente.cdNivelAlarme
                    : null,"label":"Nivel"},on:{"input":function($event){return _vm.itemChanged(item, $event)}}})],1)],1)],1)]}},{key:"item.acoes",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"row",staticStyle:{"width":"180px"}},[_c('div',{staticClass:"col-6"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateFalha(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('div',{staticClass:"col-6"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteFalha(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])]}},{key:"no-results",fn:function(){return [_vm._v(" Não foi encontrado dados para a busca: \""+_vm._s(_vm.search)+"\" ")]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(_vm.dialog)?_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('FailureAdd',{on:{"close":function($event){_vm.dialog = false},"updateFalhasList":_vm.updateFalhasList}})],1):_vm._e(),(_vm.dialogUpdate)?_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.dialogUpdate),callback:function ($$v) {_vm.dialogUpdate=$$v},expression:"dialogUpdate"}},[_c('FailureUpdate',{attrs:{"updateFailure":_vm.failure},on:{"close":function($event){_vm.dialogUpdate = false},"update:updateFailure":function($event){_vm.failure=$event},"update:update-failure":function($event){_vm.failure=$event},"updateFalhasList":_vm.updateFalhasList}})],1):_vm._e(),(_vm.dialogDelete)?_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('FailureDelete',{attrs:{"failureSelected":_vm.failure},on:{"close":_vm.closeDelete,"updateFalhasList":_vm.updateFalhasList}})],1):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.color,"right":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
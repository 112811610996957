


















import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class TopMenuBtn extends Vue {
  @Prop() icon!: string;
  @Prop() subtitle!: string;
}


import { Component, Vue } from "vue-property-decorator";
import { mutationsTelemetria, gettersTelemetria } from "@/store/Telemetria";

@Component({})
export default class KafkaMonitoring extends Vue {
  /* Computed */
  get worker() {
    return gettersTelemetria.getWorker();
  }
  get token() {
    return gettersTelemetria.getToken();
  }
  get cdCliente() {
    return gettersTelemetria.getCdCliente();
  }
  get cdUnidade() {
    return gettersTelemetria.getCdUnidade();
  }
  get hasMonitoringState() {
    return gettersTelemetria.getHasMonitoringState();
  }

  /* Hooks */
  created() {
    this.worker.addEventListener("message", this.onMessage);
    this.worker.postMessage({ type: "stopStream" });
    this.worker.postMessage({
      type: "setStream",
      payload: {
        token: this.token,
        body: JSON.stringify({
          cdCliente: [this.cdCliente],
          cdUnidade: [this.cdUnidade],
          cdEquipamento: [],
          cdGrupoEquipamento: [],
          fgTpEquipamento: [],
          cdEquipe: [],
          cdOperacao: [],
          cdGrupoAtividade: [],
          cdGrupoOperacao: []
        })
      }
    });
  }
  beforeDestroy() {
    this.worker.postMessage({ type: "stopStream" });
    this.worker.removeEventListener("message", this.onMessage);
  }

  /* Methods */
  onMessage(event: MessageEvent): void {
    if (event.data?.type === "onProcessing" && this.hasMonitoringState) {
      const payload = event.data.payload; // []
      const monitoramento = (payload as Telemetria.KafkaMonitoramento[]).filter(
        p => p.type === "monitoramento"
      );
      const alarmes = (payload as Telemetria.KafkaAlarme[]).filter(p => p.type === "alarme");
      for (const item of monitoramento) {
        this.onProcessingMonitoramento(item);
      }
      for (const item of alarmes) {
        this.onProcessingAlarmes(item);
      }
    }
  }

  onProcessingMonitoramento(item: Telemetria.KafkaMonitoramento): void {
    // aplicar os dados vindos da stream no monitoringState
    const toApply: Telemetria.ToApplyStream = Object.assign({}, item);
    // const hasAnimationPoints = Boolean(toApply.animationPoints);
    // hasAnimationPoints && mutations.pushToApplyCoordinates(toApply);
    // Encontrar no state.monitoringState qual o item a atualizar
    let found = false;
    for (const { reference } of gettersTelemetria.arrayIteratorState()) {
      if (reference.id === item.id) {
        mutationsTelemetria.updateMonitoringStateItem(toApply, reference);
        found = true;
        break;
      }
    }
    if (!found) {
      console.warn("Equipamento nao encontrado no monitoringState", item);
    }
  }

  onProcessingAlarmes(item: Telemetria.KafkaAlarme): void {
    let found = false;
    for (const { reference, index } of gettersTelemetria.arrayIteratorAlarmes()) {
      if (reference.id === item.id) {
        found = true;
        if (item.fgOnline === 0) {
          mutationsTelemetria.spliceAlarmes(index); // se estiver offline, então remove do array alarmes
        } else {
          mutationsTelemetria.updateAlarmes(item, reference);
        }
        break;
      }
    }
    if (found === false) {
      console.info("ADD ALARME", JSON.stringify(item));
      mutationsTelemetria.addAlarme(item);
    }
  }
  render() {
    return null;
  }
}

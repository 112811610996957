















































































import { Component, Vue } from "vue-property-decorator";
import { gettersTelemetria } from "@/store/Telemetria";
import axios from "axios";

@Component({})
export default class FailureAdd extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };
  /* Data */
  alert = false;
  message = "";
  color = "";
  valid = true;
  objCodigoFalha = {
    cdFalha: "",
    descFalha: "",
    cdCriticidadeCliente: {},
    cdCriticidadeFabricante: "",
    cdModulo: {}
  } as Failures.FaultCode;
  moduloRules = [(v: object) => !!v || "Módulo is required"];

  codigoFalhaRules = [
    (v: number) => !!v || "Código is required",
    (v: number) => (v && v.toString().length <= 10) || "Código must be valid"
  ];

  descricaoRules = [
    (v: string) => !!v || "Descrição is required",
    (v: string) => (v && v.length <= 500) || "Descrição must be valid"
  ];
  itemsCriticidadeCliente: Array<Failures.ListCritical> = [];
  itemsModulo = [];
  itemsModeloEquipamento = [];
  /* Methods */
  mounted() {
    this.getListaCriticidade();
  }
  validateForm() {
    if (this.$refs.form.validate()) {
      this.postCodigoFalha(this.objCodigoFalha);
    }
  }
  closeModal() {
    this.$refs.form.reset();
    this.$refs.form.resetValidation();
    this.$emit("close");
  }
  getListaCriticidade() {
    axios
      .get(gettersTelemetria.getUrlSgpa() + "/telemetria/lista-criticidade", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": gettersTelemetria.getToken()
        }
      })
      .then(response => {
        this.itemsCriticidadeCliente = response.data.filter(
          (x: Failures.ListCritical) =>
            x.descNivelAlarme === "Erro" ||
            x.descNivelAlarme === "Alarme" ||
            x.descNivelAlarme === "Alerta" ||
            x.descNivelAlarme === "Critico"
        );
      });
  }
  postCodigoFalha(data: Failures.FaultCode) {
    axios
      .post(
        gettersTelemetria.getUrlSgpa() + "/telemetria/codigos-falha/salvar",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": gettersTelemetria.getToken()
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          this.closeModal();
          this.$emit("updateFalhasList", "cadastrado");
        } else {
          console.log(response);
          this.setAlert();
        }
      })
      .catch(error => {
        console.log("ERROR:", error);
        this.setAlert();
      });
  }
  setAlert() {
    this.alert = true;
    this.message = "Erro ao cadastrar códigos de falhas!";
    this.color = "error";
  }
}

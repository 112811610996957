














import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class TopMenuCard extends Vue {
  @Prop() icon!: string;
  @Prop() title!: string;
  @Prop() titleAux!: string;
  @Prop() subtitle!: string;
}































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { gettersEvents, mutationsEvents } from "@/store/Graphics";
import axios from "axios";

@Component({})
export default class MenuPanelTemperature extends Vue {
  @Prop() sgpaapiurl!: string;
  @Prop() token!: string;
  @Prop() cdEquipamento!: number;
  @Prop() modelo!: number;

  /* data */
  parametrosList: Array<object> = [];

  /* methods */
  created() {
    this.getListaParametros();
    mutationsEvents.setTempoPainel(gettersEvents.getTempoListPainel()[0].value);
  }
  getListaMinMax() {
    axios
      .get(this.sgpaapiurl + "/parametrosRedecan/findByModel?cdModeloEquipamento=" + this.modelo, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": this.token
        }
      })
      .then(response => {
        const data = response.data;
        if (this.parametroUm) {
          const campo = gettersEvents.getMinMaxPainel().filter(x => x.tipo === this.parametroUm)[0]
            .campo;
          if (campo) {
            const campoT = campo
              .toLowerCase()
              .split("_")
              .join(" ")
              .replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
            mutationsEvents.setMinParametroUm(data[campoT + "Minimo"]);
            mutationsEvents.setMaxParametroUm(data[campoT + "Maximo"]);
          }
        }
        if (this.parametroDois) {
          const campo = gettersEvents
            .getMinMaxPainel()
            .filter(x => x.tipo === this.parametroDois)[0].campo;
          if (campo) {
            const campoT = campo
              .toLowerCase()
              .split("_")
              .join(" ")
              .replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
            mutationsEvents.setMinParametroDois(data[campoT + "Minimo"]);
            mutationsEvents.setMaxParametroDois(data[campoT + "Maximo"]);
          }
        }
        if (this.parametroTres) {
          const campo = gettersEvents
            .getMinMaxPainel()
            .filter(x => x.tipo === this.parametroTres)[0].campo;
          if (campo) {
            const campoT = campo
              .toLowerCase()
              .split("_")
              .join(" ")
              .replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
            mutationsEvents.setMinParametroTres(data[campoT + "Minimo"]);
            mutationsEvents.setMaxParametroTres(data[campoT + "Maximo"]);
          }
        }
      });
  }
  getListaParametros() {
    axios
      .get(
        this.sgpaapiurl +
          "/telemetria/lista-parametros-cd-equipamento?equipamento=" +
          this.cdEquipamento,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token
          }
        }
      )
      .then(response => {
        this.parametrosList = response.data.map((x: Array<object>) => x[0]);
        mutationsEvents.setMinMaxPainel(
          response.data
            .filter((x: Array<object>) => x[3] !== null)
            .map((x: Array<object>) => {
              return { tipo: x[0], medida: x[1], campo: x[3] };
            })
        );
      });
  }
  /* computed */
  get tempoList() {
    return gettersEvents.getTempoListPainel();
  }

  get parametroUm() {
    return gettersEvents.getValoresGraficoPainel().parametroUm;
  }
  set parametroUm(parametro) {
    mutationsEvents.setParametroUmPainel(parametro);
  }

  get parametroDois() {
    return gettersEvents.getValoresGraficoPainel().parametroDois;
  }
  set parametroDois(parametro) {
    mutationsEvents.setParametroDoisPainel(parametro);
  }

  get parametroTres() {
    return gettersEvents.getValoresGraficoPainel().parametroTres;
  }
  set parametroTres(parametro) {
    mutationsEvents.setParametroTresPainel(parametro);
  }

  get tempo() {
    return gettersEvents.getValoresGraficoPainel().tempo;
  }
  set tempo(parametro) {
    mutationsEvents.setTempoPainel(parametro);
  }
  /* watch */
  @Watch("parametroUm")
  parametroUmChanged() {
    this.getListaMinMax();
  }

  @Watch("parametroDois")
  parametroDoisChanged() {
    this.getListaMinMax();
  }

  @Watch("parametroTres")
  parametroTresChanged() {
    this.getListaMinMax();
  }
}



























import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import { gettersEvents, mutationsEvents } from "@/store/Graphics";
@Component({
  components: {}
})
export default class EngineLoadProfileRPMMotorList extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() tpEquipamento!: number;
  @Prop() bus!: Vue;
  @Prop() token!: string;
  @Prop() sgpaapiurl!: string;
  /* Data    16 x 16    */

  headers: { [key: string]: any }[] = [
    {
      text: "Torque/RPM",
      value: "torqueHeader"
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    },
    {
      text: "",
      value: ""
    }
  ];

  /* Computed */
  get headerSpliced() {
    const a = this.headers.slice(1, this.headers.length);
    return a;
  }

  get dadosRaw() {
    return gettersEvents.getPerfilCargaDadosRaw().dados;
  }

  get dados() {
    const a: { [key: string]: any }[] = [
      {
        id: 0,
        torqueHeader: ""
      },
      {
        id: 1,
        torqueHeader: ""
      },
      {
        id: 2,
        torqueHeader: ""
      },
      {
        id: 3,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 4,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 5,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 6,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 7,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 8,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 9,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 10,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 11,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 12,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 13,
        torqueHeader: "",
        tipo: 5
      },
      {
        id: 14,
        torqueHeader: "",
        tipo: 5
      }
    ];
    if (this.dadosRaw.length !== 0) {
      for (let i = 0; i < 15; i++) {
        for (let j = 0; j < 15; j++) {
          a[i]["content" + this.dadosRaw[j * 15 + i].minRpm] = this.dadosRaw[j * 15 + i].horas;
          a[i]["tipo" + this.dadosRaw[j * 15 + i].minRpm] = this.dadosRaw[j * 15 + i].tipo;
          this.headers[i + 1].text = this.dadosRaw[i * 15 + j].minRpm.toFixed(0);
          this.headers[i + 1].value = "content" + this.dadosRaw[i * 15 + j].minRpm;
        }

        a[i]["torqueHeader"] = this.dadosRaw[i].minTorque.toFixed(0);
      }
    }
    mutationsEvents.setPerfilCargaDados(a);
    return a;
  }

  get dataInicio() {
    return gettersEvents.getPerfilCarga().dataInicio;
  }

  get dataFim() {
    return gettersEvents.getPerfilCarga().dataFim;
  }

  /* Methods */

  getItemColor(valor: { [key: string]: string }, item: { [key: string]: any }) {
    const val = valor.value;
    return "style" + item["tipo" + val.slice(7, val.length)];
  }
}

































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { gettersEvents, mutationsEvents } from "@/store/Graphics";
import jsPDF from "jspdf";
import "jspdf-autotable";

@Component({})
export default class MenuFilterHistory extends Vue {
  @Prop() dataGraficoHistoricoFalhas!: Array<object>;
  /* data */
  criticidadeList = [
    { id: "", desc: "Tudo" },
    { id: "247", desc: "Erro" },
    { id: "246", desc: "Alerta" },
    { id: "1", desc: "Crítico" }
  ];
  /* methods */
  allowDates = (val: Date) => new Date(val) <= new Date();
  limparFiltro() {
    mutationsEvents.setCriticidade(0);
    mutationsEvents.setOperador("");
    mutationsEvents.setSpn("");
    mutationsEvents.setDateStart(new Date().toISOString().substr(0, 10));
    mutationsEvents.setDateEnd(new Date().toISOString().substr(0, 10));
  }
  generatePDF() {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const dataToExport: any = this.dataGraficoHistoricoFalhas[0];

    const pdf: any = new jsPDF("landscape");
    let header: object = [];
    header = dataToExport[1];

    let dados: Array<Global.DataToExport> = [];
    dados = dataToExport[0];

    const data = dados.map((x: Global.DataToExport) => {
      return {
        Data: x.data,
        Hora: x.hora,
        Criticidade: x.criticidade,
        Horímetro: x.horimetro,
        Operador: x.operador,
        Código: x.codAlarme,
        Descrição: x.descricao,
        Duração: x.duracao
      };
    });

    pdf.text("Histórico de Falhas", 30, 20);
    pdf.autoTable(header, data, {
      startY: 25,
      startX: 20
    });

    const dateActual = new Date(); //.toLocaleString("pt-BR");
    const stringFileWithDate =
      "historico-de-falhas-" +
      dateActual.getDay() +
      "" +
      dateActual.getMonth() +
      "" +
      dateActual.getFullYear() +
      "" +
      dateActual.getHours() +
      "" +
      dateActual.getMinutes() +
      "" +
      dateActual.getSeconds() +
      ".pdf";

    pdf.save(stringFileWithDate);
  }
  /* computed */
  get criticidade() {
    return gettersEvents.getFilterFailures().criticidade;
  }
  set criticidade(criticidade) {
    console.log(criticidade);
    mutationsEvents.setCriticidade(criticidade);
  }

  get dataStart() {
    return gettersEvents.getFilterFailures().dateStart;
  }
  set dataStart(dataStart) {
    mutationsEvents.setDateStart(dataStart);
  }

  get dataEnd() {
    return gettersEvents.getFilterFailures().dateEnd;
  }
  set dataEnd(dataEnd) {
    mutationsEvents.setDateEnd(dataEnd);
  }

  get operador() {
    return gettersEvents.getFilterFailures().operador;
  }
  set operador(operador) {
    mutationsEvents.setOperador(operador);
  }

  get spn() {
    return gettersEvents.getFilterFailures().spn;
  }
  set spn(spn) {
    mutationsEvents.setSpn(spn);
  }

  // get fmi() {
  //   return gettersEvents.getFiltroHistorico().fmi;
  // }
  // set fmi(fmi) {
  //   mutationsEvents.setFmi(fmi);
  // }

  // get horimetro() {
  //   return gettersEvents.getFiltroHistorico().horimetro;
  // }
  // set horimetro(horimetro) {
  //   mutationsEvents.setHorimetro(horimetro);
  // }
}


import { Component, Vue } from "vue-property-decorator";
import { mutationsTelemetria, gettersTelemetria } from "@/store/Telemetria";

@Component({})
export default class LoadActualStateAlarm extends Vue {
  /* Computed */
  get worker() {
    return gettersTelemetria.getWorker();
  }
  get token() {
    return gettersTelemetria.getToken();
  }
  get cdCliente() {
    return gettersTelemetria.getCdCliente();
  }
  get cdUnidade() {
    return gettersTelemetria.getCdUnidade();
  }
  /* Hooks */
  created() {
    this.worker.addEventListener("message", this.onMessage);
    this.worker.postMessage({
      type: "loadAlarmes",
      payload: {
        token: this.token,
        body: JSON.stringify({
          cdCliente: [this.cdCliente],
          cdUnidade: [this.cdUnidade],
          cdEquipamento: [],
          cdGrupoEquipamento: [],
          fgTpEquipamento: [],
          cdEquipe: [],
          cdOperacao: [],
          cdGrupoAtividade: [],
          cdGrupoOperacao: []
        })
      }
    });
  }

  beforeDestroy() {
    this.worker.removeEventListener("message", this.onMessage);
  }

  /* Methods */
  onMessage(message: MessageEvent): void {
    if (message.data?.type === "onAlarmes") {
      const messageData = message.data as Telemetria.KafkaMessage;
      // alarmes sempre retorna um array, mesmo se nao existir dados;
      const alarmes = messageData.payload as Telemetria.ActualStateAlarm[];
      mutationsTelemetria.setAlarmes(alarmes);
    }
  }

  render() {
    return null;
  }
}

















import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { gettersEvents } from "@/store/Graphics";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

@Component({
  components: {
    apexcharts: VueApexCharts
  }
})
export default class GraphicsCounterManeuver extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() token!: string;
  @Prop() sgpaapiurl!: string;
  @Prop() bus!: Vue;
  /* data */
  series = [44, 55];
  chartOptions = {
    chart: {
      width: "100%",
      height: "100%",
      type: "pie"
    },
    legend: {
      position: "bottom"
    },
    labels: ["Direito", "Esquerdo"]
  };
  /* Hooks */
  created() {
    this.bus.$on("filterChild", () => {
      this.getContadorManobra();
    });
  }
  /* methods */
  getContadorManobra() {
    axios({
      method: "post",
      url: this.sgpaapiurl + "/telemetria/contador-manobra",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": this.token
      },
      data: {
        equipamento: this.cdEquipamento,
        dataInicio: this.dataInicio,
        dataFim: this.dataFim
      }
    }).then(response => {
      const data = response.data;
      this.series = [data.esquerda, data.direita];
      this.chartOptions = {
        ...this.chartOptions,
        ...{ labels: ["Esquerda", "Direita"] }
      };
    });
  }
  /* computed */
  get dataInicio() {
    return gettersEvents.getFilterSensors().dataInicio;
  }
  get dataFim() {
    return gettersEvents.getFilterSensors().dataFim;
  }
}

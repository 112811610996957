









import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { gettersEvents } from "@/store/Graphics";
import axios from "axios";

@Component({
  components: {
    apexcharts: VueApexCharts
  }
})
export default class GraphicsDurationFailure extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() token!: string;
  @Prop() sgpaapiurl!: string;
  @Prop() bus!: Vue;
  /* data */
  series = [
    {
      data: []
    }
  ];
  chartOptions: object = {
    chart: {
      type: "bar",
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"]
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"]
    },

    xaxis: {
      labels: {
        hideOverlappingLabels: false,
        rotateAlways: true
      },
      categories: []
    },
    tooltip: {
      y: {
        formatter: function(y: number) {
          const h = Math.floor(y / 3600);
          const m = Math.floor((y % 3600) / 60);
          const s = Math.floor((y % 3600) % 60);
          return h + ":" + m + ":" + s + "(H:M:S)";
        }
      }
    }
  };

  /* computed */
  get dataStart() {
    return gettersEvents.getFilterFailures().dateStart;
  }
  get dataEnd() {
    return gettersEvents.getFilterFailures().dateEnd;
  }
  get criticidade() {
    return gettersEvents.getFilterFailures().criticidade;
  }
  get operador() {
    return gettersEvents.getFilterFailures().operador;
  }
  get spn() {
    return gettersEvents.getFilterFailures().spn;
  }

  /* Hooks */
  created() {
    this.bus.$on("filterChild", () => {
      this.getDuracaoFalha();
    });
  }
  /* methods */
  getDuracaoFalha() {
    const f = (accumulator: string, currentValue: string) => accumulator + currentValue;
    const dtStart = this.dataStart
      .split("-")
      .reverse()
      .reduce(f);
    const dtEnd = this.dataEnd
      .split("-")
      .reverse()
      .reduce(f);
    return axios
      .get(
        this.sgpaapiurl +
          "/telemetria/duracao-falha?cd_equipamento=" +
          this.cdEquipamento +
          "&criticidade=" +
          this.criticidade +
          "&spn=" +
          this.spn +
          "&operador=" +
          this.operador +
          "&data_inicio=" +
          dtStart +
          "&data_fim=" +
          dtEnd,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token
          }
        }
      )
      .then(response => {
        this.series = [
          {
            data: response.data.map((x: Array<object>) => x[2])
          }
        ];
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: response.data.map((x: Array<string>) => x[1].concat(" - ").concat(x[0]))
            }
          }
        };
      });
  }
}

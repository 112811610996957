
























































































import { Component, Prop, Vue } from "vue-property-decorator";
import MenuSensors from "../menu/MenuSensors.vue";
import { gettersTelemetria } from "@/store/Telemetria";
import EngineLoadProfileList from "../EngineLoadProfile/EngineLoadProfileList.vue";
import EngineLoadProfileRPMMotorList from "../EngineLoadProfile/EngineLoadProfileRPMMotorList.vue";
import EngineLoadProfileFilter from "../EngineLoadProfile/EngineLoadProfileFilter.vue";
import GraphicsPanelTemperature from "../graphics/GraphicsPanelTemperature.vue";
import GraphicsCounterManeuver from "../graphics/GraphicsCounterManeuver.vue";
import GraphicsWeatherTrend from "../graphics/GraphicsWeatherTrend.vue";
import axios from "axios";

@Component({
  components: {
    MenuSensors,
    EngineLoadProfileList,
    EngineLoadProfileRPMMotorList,
    EngineLoadProfileFilter,
    GraphicsPanelTemperature,
    GraphicsCounterManeuver,
    GraphicsWeatherTrend
  }
})
export default class MaintenanceSensors extends Vue {
  @Prop() equipamento!: Global.Equipamento;

  /* data */
  bus = new Vue();
  modeloEquipamento = 0;

  /* methods */
  filter() {
    this.bus.$emit("filterChild", "filter");
  }

  getModeloEquipamento() {
    axios
      .get(
        gettersTelemetria.getUrlSgpa() + "/equipamento/fulldto/" + this.equipamento.cdEquipamento,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": gettersTelemetria.getToken()
          }
        }
      )
      .then(response => {
        this.modeloEquipamento = response.data.cdModeloEquipamento;
      });
  }

  /*Hooks*/
  created() {
    this.getModeloEquipamento();
  }

  /* computed */
  get sgpaapiurl() {
    return gettersTelemetria.getUrlSgpa();
  }

  get token() {
    return gettersTelemetria.getToken();
  }
}

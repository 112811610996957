
import { Component, Vue } from "vue-property-decorator";
import { mutationsTelemetria, gettersTelemetria } from "@/store/Telemetria";

// handler para o Proxy do ActualState
const handler = {
  get: function(target: Telemetria.ActualState, name: string) {
    if (name === "url") {
      const icon =
        target["fgMonitoramentoAtivo"] === "F"
          ? target["descIcone"] + "_off.png"
          : target["descIcone"] + ".png";
      return `icons/${icon}`;
    }
    if (name === "vlDirecao") {
      if (target["vlDirecao"]) {
        return 90 - target["vlDirecao"];
      }
      return 0;
    }
    // if (name === "vlAlarme") {
    //   // Procura no alarmes se existe algo correspondente. Caso contrario retorna null
    //   return (
    //     getters.getAlarmes().find(alarme => alarme.id === target["id"])
    //       ?.vlAlarme || null
    //   );
    // }
    return Reflect.get(target, name);
  },
  set: function(target: Telemetria.ActualState, name: string, value: never) {
    // console.log("setting", name, Reflect.get(target, name), value);
    if (typeof value === "number") {
      Reflect.set(target, name, value);
    } else {
      value === null || value === undefined
        ? Reflect.get(target, name)
        : Reflect.set(target, name, value);
    }
    return true;
  }
};

@Component({})
export default class LoadActualState extends Vue {
  error!: Error | null;
  showSnackbar = false;
  snackbarText = "";
  /* Computed */
  get worker() {
    return gettersTelemetria.getWorker();
  }
  get token() {
    return gettersTelemetria.getToken();
  }
  get cdCliente() {
    return gettersTelemetria.getCdCliente();
  }
  get cdunidade() {
    return gettersTelemetria.getCdUnidade();
  }
  /* Hooks */
  created() {
    this.worker.addEventListener("message", this.onMessage);
    this.worker.postMessage({
      type: "loadActualState",
      payload: {
        token: this.token,
        body: JSON.stringify({
          cdCliente: [this.cdCliente],
          cdUnidade: [this.cdunidade],
          cdEquipamento: [],
          cdGrupoEquipamento: [],
          fgTpEquipamento: [],
          cdEquipe: [],
          cdOperacao: [],
          cdGrupoAtividade: [],
          cdGrupoOperacao: []
        })
      }
    });
  }

  beforeDestroy() {
    this.worker.removeEventListener("message", this.onMessage);
  }

  /* Methods */
  render() {
    return null;
  }
  onMessage(message: MessageEvent): void {
    if (message.data?.type === "onActualState") {
      const messageData = message.data as Telemetria.KafkaMessage;
      const payload = messageData.payload;
      // this.$emit("onActualState", false);
      mutationsTelemetria.resetMonitoringState();
      for (const item of this.arrayIterator(
        payload as Telemetria.ActualState[]
      )) {
        mutationsTelemetria.pushMonitoringState(new Proxy(item, handler));
      }
      gettersTelemetria.getMonitoringState().length &&
        this.$emit("onActualState", true);
      this.worker.removeEventListener("message", this.onMessage);
    }
    if (message.data?.type === "onActualStateError") {
      const messageData = message.data as Telemetria.KafkaMessage;
      const payload = messageData.payload;
      console.error(payload);
      this.worker.removeEventListener("message", this.onMessage);
      this.showSnackbar = true;
      this.snackbarText = payload as string;
    }
  }

  *arrayIterator(
    array: Telemetria.ActualState[]
  ): IterableIterator<Telemetria.ActualState> {
    for (const item of array) yield item;
  }
}













import { Component, Vue } from "vue-property-decorator";
import Dashboard from "./components/Dashboard/Dashboard.vue";
import TelemetriaState from "./components/TelemetriaState/TelemetriaState.vue";
import { mutationsTelemetria } from "@/store/Telemetria";
const IS_PROD = process.env.NODE_ENV === "production";

@Component({
  components: {
    Dashboard,
    TelemetriaState
  }
})
export default class Telemetria extends Vue {
  // Props
  cdCliente = parseInt(process.env.VUE_APP_DEFAULT_CDCLIENTE || "100004");
  cdUnidade: number = parseInt(process.env.VUE_APP_DEFAULT_CDUNIDADE || "50");
  locale = IS_PROD ? (process.env.VUE_APP_I18N_LOCALE as string) : "pt-br";
  urlSgpa = process.env.VUE_APP_DEFAULT_URLSGPA || "http://localhost:4445";
  urlMonit = process.env.VUE_APP_MONITORAMENTO_BASE_URL || "http://localhost:4321/v1";
  token = process.env.VUE_APP_TOKEN || "";

  propsResolved = false; // true somente quando as props forem recebidas e validadas

  /* Hooks */
  created() {
    if (IS_PROD) {
      // se for production espera os valores do consumidor
      parent.window.postMessage("solinftec-pwa-telemetria:loaded", "*");
      window.addEventListener("message", this.onWindowMessage, false);
    } else {
      // se for development pega os valores já definidos
      this.pipeline();
    }
  }
  beforeDestroy() {
    if (IS_PROD) {
      window.removeEventListener("message", this.onWindowMessage, false);
    }
  }

  /* Methods */
  pipeline(): void {
    mutationsTelemetria.setCdCliente(this.cdCliente);
    mutationsTelemetria.setToken(this.token);
    mutationsTelemetria.setCdUnidade(this.cdUnidade);
    mutationsTelemetria.setUrlSgpa(this.urlSgpa);
    mutationsTelemetria.setUrlMonit(this.urlMonit);
    this.$i18n.locale = this.locale;
    this.propsResolved = true;
  }

  checkPostedMessage(data?: Telemetria.Props) {
    return (
      typeof data?.cdCliente === "number" &&
      typeof data?.cdUnidade === "number" &&
      typeof data?.token === "string" &&
      typeof data?.urlSgpa === "string" &&
      typeof data?.locale === "string"
    );
  }
  onWindowMessage(e: MessageEvent) {
    try {
      const message = JSON.parse(e.data);
      if (message?.type === "props" && this.checkPostedMessage(message?.data)) {
        const { cdCliente, cdUnidade, token, locale, urlSgpa } = message.data as Telemetria.Props;
        this.cdCliente = cdCliente;
        this.cdUnidade = cdUnidade;
        this.token = token;
        this.urlSgpa = urlSgpa;
        this.locale = locale;
        console.log("message received", message?.data);
        parent.window.postMessage("message received", "*");
        this.propsResolved = false;
        this.pipeline();
      }
    } catch (e) {
      console.error(e);
    }
  }
}







































































import MenuFailures from "../menu/MenuFailures.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import GraphicsAvailability from "../graphics/GraphicsAvailability.vue";
import GraphicsTimeLineFailures from "../graphics/GraphicsTimeLineFailures.vue";
import GraphicsFailModule from "../graphics/GraphicsFailModule.vue";
import GraphicsDurationFailure from "../graphics/GraphicsDurationFailure.vue";
import GraphicsHistoricalFailures from "../graphics/GraphicsHistoricalFailures.vue";
import GraphicsRankingRecurrence from "../graphics/GraphicsRankingRecurrence.vue";
import { gettersTelemetria } from "@/store/Telemetria";

@Component({
  components: {
    MenuFailures,
    GraphicsAvailability,
    GraphicsTimeLineFailures,
    GraphicsFailModule,
    GraphicsDurationFailure,
    GraphicsHistoricalFailures,
    GraphicsRankingRecurrence
  }
})
export default class MaintenanceFailures extends Vue {
  @Prop() cdEquipamento!: number;

  /* data */
  bus = new Vue();

  /* methods */
  filter() {
    this.bus.$emit("filterChild", "filter");
  }

  /* Computed */
  get sgpaapiurl() {
    return gettersTelemetria.getUrlSgpa();
  }

  get token() {
    return gettersTelemetria.getToken();
  }
}

<template>
  <v-container fluid class="pa-1">
    <v-row no-gutters>
      <v-col>
        <BannerTitle icon="mdi-hammer-wrench" titleText="Telemetria" :showExpand="false" />
        <!-- <v-banner single-line class="pa-1" color="grey darken-3">
          <v-icon slot="icon" color="white">
            mdi-hammer-wrench
          </v-icon>
          <p class="title white--text mb-0">Telemetria</p>
        </v-banner> -->
      </v-col>
    </v-row>

    <!-- <header><v-icon dark></v-icon> Telemetria</header> -->
    <TopMenu @onFailure="failure = true" @onAlert="alert = true" />
    <v-row>
      <v-col cols="8"><equipments></equipments> </v-col>
      <v-col cols="4"><recent-failures></recent-failures></v-col>
    </v-row>

    <v-dialog v-if="failure" width="90%" v-model="failure">
      <failure-list @close="failure = false" />
    </v-dialog>
    <v-dialog v-if="alert" max-width="700" v-model="alert">
      <alert-add @close="alert = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import RecentFailures from "../RecentFailures.vue";
import TopMenu from "./TopMenu.vue";
import BannerTitle from "../Common/BannerTitle.vue";
import Equipments from "../Equipments.vue";
import { gettersTelemetria } from "@/store/Telemetria";
import FailureList from "../failure/FailureList.vue";
import AlertAdd from "../AlertAdd.vue";

@Component({
  components: {
    TopMenu,
    BannerTitle,
    RecentFailures,
    Equipments,
    FailureList,
    AlertAdd
  }
})
export default class Dashboard extends Vue {
  failure = false;
  alert = false;

  /* Computed */
  get counts() {
    return gettersTelemetria.getCounts();
  }
}
</script>

<style>
.text-title-card {
  background-color: #4c4f53;
}

.text-title-span {
  color: white;
}

header {
  display: block;
  height: 34px;
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-align: inherit;
  border-color: #45474b !important;
  background: #4c4f53;
  color: #fff;
}

.lowercase {
  text-transform: none !important;
}
</style>












































import { Component, Vue } from "vue-property-decorator";
import { mutationsEvents, gettersEvents } from "@/store/Graphics";

@Component({})
export default class MenuWeatherTrend extends Vue {
  created() {
    mutationsEvents.setTempo(gettersEvents.getTempoList()[0].value);
  }
  /* computed */
  get parametrosList() {
    const parametros = gettersEvents.getMinMaxPainel().map(x => x.tipo);
    parametros.unshift("Código do Estado");
    return parametros;
  }
  get tempoList() {
    return gettersEvents.getTempoList();
  }
  get parametroUm() {
    return gettersEvents.getValoresGraficoTendencia().parametroUm;
  }
  set parametroUm(parametro) {
    mutationsEvents.setParametroUm(parametro);
  }

  get parametroDois() {
    return gettersEvents.getValoresGraficoTendencia().parametroDois;
  }
  set parametroDois(parametro) {
    mutationsEvents.setParametroDois(parametro);
  }

  get parametroTres() {
    return gettersEvents.getValoresGraficoTendencia().parametroTres;
  }
  set parametroTres(parametro) {
    mutationsEvents.setParametroTres(parametro);
  }

  get tempo() {
    return gettersEvents.getValoresGraficoTendencia().tempo;
  }
  set tempo(parametro) {
    mutationsEvents.setTempo(parametro);
  }
}
































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ProgressBar extends Vue {
  @Prop({})
  frente!: { [key: string]: any };
}






































































































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import { dayjs } from "@/helpers/date-helper";
import { gettersTelemetria } from "@/store/Telemetria";

@Component({})
export default class MaintenanceDetail extends Vue {
  @Prop() equipamento!: Global.Equipamento;
  @Prop() cdCliente!: number;
  @Prop() token!: string;

  /* data */
  equipamentoDetalhes: Global.DescEquipamento | null = null;
  showCardDetails = false;

  /* methods */
  mounted() {
    this.getDetalhesEquipamentoDoCadastro();
  }
  urlIcons(url: string): string {
    return process.env.VUE_APP_CDN_BASE_URL + url;
  }

  getDetalhesEquipamento() {
    axios
      .get(this.sgpaapiurl + "/telemetria/equipamento-detalhes/" + this.equipamento.cdEquipamento, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": this.token
        }
      })
      .then(response => {
        const vData = response.data;
        this.equipamentoDetalhes = vData[0];

        if (this.equipamentoDetalhes) {
          this.showCardDetails = true;
        } else {
          this.getDetalhesEquipamentoDoCadastro();
          this.showCardDetails = false;
        }
      })
      .catch(error => {
        console.error(error);
        this.showCardDetails = true;
      });
  }
  getDetalhesEquipamentoDoCadastro() {
    axios
      .get(this.sgpaapiurl + "/equipamento/fulldto/" + this.equipamento.cdEquipamento, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": this.token
        }
      })
      .then(response => {
        const responseData = response.data;
        const vEquipamento = responseData.cdEquipamento + " - " + responseData.descEquipamento;
        const vUnidade = responseData.cdUnidade + " - " + responseData.descUnidade;
        const vGrupoEquipamento =
          responseData.cdGrupoEquipamento + " - " + responseData.descGrupoEquipamento;
        const vModelo = responseData.descModeloEquipamento;
        const vOperador = this.equipamento.descOperador.split("-")[1];
        const vDescFabricante = response.data.descFabricante;
        const equipObj = [];
        equipObj.push({
          equipamento: vEquipamento,
          unidade: vUnidade,
          modelo: vModelo,
          horimetro: null,
          frente: vGrupoEquipamento,
          operador: vOperador,
          operacao: null,
          ultimaAtualizacao: null,
          descFabricante: vDescFabricante
        });
        this.getHorimetroUltimaAtualizacao(equipObj[0]);
      })
      .catch(error => {
        console.error(error);
        this.equipamentoDetalhes = null;
        this.showCardDetails = true;
      });
  }

  getHorimetroUltimaAtualizacao(obj: Global.DescEquipamento) {
    axios
      .get(
        this.moniturl + "/equipmentInfo/" + this.cdCliente + "/" + this.equipamento.cdEquipamento,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token,
            "request-origin": "sgp-front"
          }
        }
      )
      .then(response => {
        const data = response.data;
        const objAtualizado = {
          ...obj,
          horimetro: data.horimetro,
          operacao: data.descOperacao,
          ultimaAtualizacao: data.dtHrLocal
        };
        this.equipamentoDetalhes = objAtualizado;
        this.showCardDetails = true;
      });
  }
  /* computed */
  get getDateStringFormated() {
    if (this.equipamentoDetalhes?.ultimaAtualizacao) {
      const dataUltimaAtualizacao = this.equipamentoDetalhes.ultimaAtualizacao;
      return dayjs.utc(dataUltimaAtualizacao).format("DD/MM/YYYY - HH:mm:ss");
    }
    return "Sem dados";
  }

  get sgpaapiurl() {
    return gettersTelemetria.getUrlSgpa();
  }

  get moniturl() {
    return gettersTelemetria.getUrlMonit();
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Worker from "worker-loader!../workers/Telemetria/monitoringWorker";

const state = Vue.observable({
  monitoringState: [] as Array<Telemetria.ActualState>,
  alarmes: [] as Array<Telemetria.ActualStateAlarm>,
  worker: new Worker(),
  cdCliente: -1,
  cdUnidade: -1,
  urlSgpa: "",
  urlMonit: "",
  token: "",
  counts: { count9: 0, count10: 0, countM: 0, countD: 0, countTotal: 0 },
  falhasT: [] as { [key: string]: any }[]
});

export const gettersTelemetria = {
  getCdCliente: () => state.cdCliente,
  getCdUnidade: () => state.cdUnidade,
  getToken: () => state.token,
  getUrlSgpa: () => state.urlSgpa,
  getUrlMonit: () => state.urlMonit,
  getCounts: () => state.counts,
  getWorker: () => state.worker,
  getMonitoringState: () => state.monitoringState,
  getHasMonitoringState: () => state.monitoringState.length >= 1,
  getAlarmes: () => state.alarmes,
  arrayIteratorState: function*(): IterableIterator<Telemetria.ActualStateIterator> {
    for (let i = 0; i < state.monitoringState.length; i++) {
      yield { index: i, reference: state.monitoringState[i] };
    }
  },
  arrayIteratorAlarmes: function*(): IterableIterator<{
    index: number;
    reference: Telemetria.ActualStateAlarm;
  }> {
    for (let i = 0; i < state.alarmes.length; i++) {
      yield { index: i, reference: state.alarmes[i] };
    }
  },
  getMonitoringOnline: () => state.monitoringState.filter(x => x.fgMonitoramentoAtivo === "T"),
  getMonitoringOffline: () => state.monitoringState.filter(x => x.fgMonitoramentoAtivo === "F"),
  getFalhas: () => state.alarmes.filter(x => x.fgClassificacao === 9),
  getFalhasParametro: () => state.alarmes.filter(x => x.fgClassificacao === 10),
  getFalhasT: () => state.falhasT
};

export const mutationsTelemetria = {
  setCdCliente: (cdCliente: number) => {
    state.cdCliente = cdCliente;
  },
  setFalhas: (falhas: { [key: string]: any }[]) => {
    state.falhasT = falhas;
  },
  setCounts: (counts: {
    count9: number;
    count10: number;
    countM: number;
    countD: number;
    countTotal: number;
  }) => {
    state.counts = counts;
  },
  setCdUnidade: (cdUnidade: number) => {
    state.cdUnidade = cdUnidade;
  },
  setToken: (token: string) => {
    state.token = token;
  },
  setUrlSgpa: (url: string) => {
    state.urlSgpa = url;
  },
  setUrlMonit: (url: string) => {
    state.urlMonit = url;
  },
  resetMonitoringState: () => {
    state.monitoringState = [];
  },
  pushMonitoringState: (item: Telemetria.ActualState) => state.monitoringState.push(item),
  setAlarmes: (alarmes: Telemetria.ActualStateAlarm[]) => {
    state.alarmes = alarmes;
  },
  /**
   * Aplica no state.monitoringState as diffs vindas do Kafka
   */
  updateMonitoringStateItem: (
    item: Telemetria.ToApplyStream,
    reference: Telemetria.ActualState
  ) => {
    // console.log("atualizando dados", item);
    // console.log(reference);
    reference.dtHrLocal = item?.dtHrLocal;
    reference.geohash = item?.geohash;
    reference.vlDirecao = item?.vlDirecao;
    reference.vlGrafico = item?.vlGrafico;
    reference.fgMonitoramentoAtivo = item?.fgMonitoramentoAtivo;
    // state.hasKafkaStream = Math.random();
  },
  addAlarme: (item: Telemetria.KafkaAlarme) => {
    // NAO ESTÁ VINDO 'descEquipamento', nem 'descOperador' e nem 'cdOperador' no kafka de alarme;
    // vou setar como uma string vazia;
    state.alarmes.push(
      Object.assign({}, item, {
        descEquipamento: "",
        descOperador: "",
        cdOperador: ""
      })
    );
  },
  updateAlarmes: (item: Telemetria.KafkaAlarme, reference: Telemetria.ActualStateAlarm): void => {
    reference.cdId = item.cdId;
    reference.descAlarme = item.descAlarme;
    reference.duracao = item.duracao;
    reference.fgClassificacao = item.fgClassificacao;
    reference.fgOnline = item.fgOnline;
    reference.isAlarme = item.isAlarme;
    reference.vlAlarme = item.vlAlarme;
    reference.vlPeso = item.vlPeso;
    reference.cdEspecialAlarme = item.cdEspecialAlarme;
    console.log("UPDATE ALARMES");
  },
  spliceAlarmes: (index: number) => {
    console.log("SPLICE ALARMES");
    state.alarmes.splice(index, 1);
  }
};

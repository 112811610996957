











import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { gettersEvents } from "@/store/Graphics";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";

@Component({
  components: {
    apexcharts: VueApexCharts
  }
})
export default class GraphicsWeatherTrend extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() sgpaapiurl!: string;
  @Prop() token!: string;
  @Prop() bus!: Vue;
  /* data */
  series: object = [];
  chartOptions: object = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [2, 2, 2],
      curve: "straight"
    },
    title: {
      text: "Page Statistics",
      align: "left"
    },
    legend: {
      /* eslint-disable @typescript-eslint/no-explicit-any*/
      tooltipHoverFormatter: function(val: string, opts: any) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + "";
      }
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6
      }
    },
    xaxis: {
      categories: []
    },
    colors: ["#000", "#00FFFF", "#D2691E", "#FF0000"],
    grid: {
      borderColor: "#f1f1f1"
    }
  };
  /* methods */

  created() {
    this.bus.$on("filterChild", () => {
      this.getTendenciaMeteorologica();
    });
  }
  getTendenciaMeteorologica() {
    const parametrosTemp = [];
    for (let i = 0; i < 4; i++) {
      if (this.parametros[i] === undefined) {
        parametrosTemp.push("");
      } else {
        parametrosTemp.push(this.parametros[i]);
      }
    }
    axios({
      method: "post",
      url: this.sgpaapiurl + "/telemetria/tendencia-meteorologica",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": this.token
      },
      data: {
        equipamento: this.cdEquipamento,
        parametros: parametrosTemp,
        dataInicio: this.dataInicio,
        dataFim: this.dataFim
      }
    }).then(response => {
      const series = [];
      const yaxis = [];
      const y = [];
      const dados = response.data.dados;
      const datas = response.data.datas;
      for (let j = 0; j < 4; j++) {
        if (dados[j].parametro != "Não cadastrado") {
          series.push({
            name: dados[j].parametro,
            data: dados[j].valores
          });

          if (dados[j].parametro === "Código do Estado") {
            const estados = [
              "Parado",
              "Nenhum",
              "Deslocamento Vazio",
              "Manobra",
              "Deslocamento Carregado",
              "Efetivo",
              "Basculamento"
            ];
            y.push({
              formatter: function(y: number) {
                if (y === -1) {
                  return y.toFixed(0) + " - Ausência de Dados";
                }
                return y.toFixed(0) + " - " + estados[y];
              }
            });
          } else {
            y.push({
              formatter: function(y: number) {
                if (y === -1) {
                  return y.toFixed(0) + " - Ausência de Dados";
                }
                return y.toFixed(0);
              }
            });
          }
          if (j === 0) {
            yaxis.push({
              title: {
                text: dados[j].parametro,
                style: {
                  color: "#000"
                }
              },
              labels: {
                style: {
                  colors: "#000"
                }
              }
            });
          } else if (j === 1) {
            yaxis.push({
              opposite: true,
              title: {
                text: dados[j].parametro,
                style: {
                  color: "#00FFFF"
                }
              },
              labels: {
                style: {
                  colors: "#00FFFF"
                }
              }
            });
          } else if (j === 2) {
            yaxis.push({
              opposite: true,
              title: {
                text: dados[j].parametro,
                style: {
                  color: "#D2691E"
                }
              },
              labels: {
                style: {
                  colors: "#D2691E"
                }
              }
            });
          } else {
            yaxis.push({
              opposite: true,
              title: {
                text: dados[j].parametro,
                style: {
                  color: "#FF0000"
                }
              },
              labels: {
                style: {
                  colors: "#FF0000"
                }
              }
            });
          }
        }
      }

      this.series = series;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: { categories: datas },
          yaxis: yaxis,
          tooltip: { y: y }
        }
      };
    });
  }
  /* computed */
  get parametros() {
    return gettersEvents.getFilterSensors().parametros;
  }
  get dataInicio() {
    return gettersEvents.getFilterSensors().dataInicio;
  }
  get dataFim() {
    return gettersEvents.getFilterSensors().dataFim;
  }
}

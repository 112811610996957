





























































































































































import { Prop, Vue, Component, Watch } from "vue-property-decorator";
import axios from "axios";
import { gettersEvents, mutationsEvents } from "@/store/Graphics";
import moment from "moment";

@Component({
  components: {}
})
export default class MenuSensors extends Vue {
  @Prop() sgpaapiurl!: string;
  @Prop() cdEquipamento!: number;
  @Prop() token!: string;

  /* data */
  timeStart = "00:00";
  timeEnd = "23:59";
  time = this.timeStart + " - " + this.timeEnd;
  date = new Date().toISOString().split("T")[0];
  parametrosList: string[] = [];
  parametros: string[] = [];
  tempoRadio = "Tempo Real";
  tempoReal = "";
  tempoList = [
    { text: "10 minutos", value: 600 },
    { text: "15 minutos", value: 900 },
    { text: "30 minutos", value: 1800 },
    { text: "1 hora", value: 3600 },
    { text: "3 horas", value: 10800 },
    { text: "7 horas", value: 25200 },
    { text: "14 horas", value: 50400 },
    { text: "24 horas", value: 86400 }
  ];

  /* hooks */
  created() {
    this.getListaParametros();
  }

  /* computed */
  get formatDate() {
    return moment(this.date).format("DD/MM/YYYY");
  }

  get pList() {
    return gettersEvents.getParametrosList();
  }

  /* methods */
  clearParametros() {
    this.parametros = [];
  }

  allowDates = (val: string) => new Date(val) <= new Date();

  filter() {
    let dataInicial = this.formatDate + " " + this.timeStart + ":00";
    let dataFim = this.formatDate + " " + this.timeEnd + ":59";
    if (this.tempoRadio === "Tempo Real") {
      dataFim = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
      dataInicial = moment(new Date())
        .subtract(this.tempoList.filter(x => x.text === this.tempoReal)[0].value, "seconds")
        .format("DD/MM/YYYY HH:mm:ss");
    }
    mutationsEvents.setDataInicio(dataInicial);
    mutationsEvents.setDataFim(dataFim);
    mutationsEvents.setParametros(this.parametros);

    this.$emit("filter", "filter");
  }

  getListaParametros() {
    axios
      .get(
        this.sgpaapiurl +
          "/telemetria/lista-parametros-cd-equipamento?equipamento=" +
          this.cdEquipamento,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token
          }
        }
      )
      .then(response => {
        const data = response.data;
        this.parametrosList = data.map((x: { [key: string]: any }) => x.descricao);
        this.parametrosList.push("Código do Estado");
        mutationsEvents.setMinMaxPainel(
          data
            .filter((x: { [key: string]: any }) => x.campo !== null)
            .map((x: { [key: string]: any }) => {
              return { tipo: x.descricao, medida: x.unidade, campo: x.campo };
            })
        );
      });
  }
  @Watch("time")
  timeChanged() {
    this.time = this.timeStart + " - " + this.timeEnd;
  }
}













import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { gettersEvents } from "@/store/Graphics";
import axios from "axios";

@Component({
  components: {
    apexcharts: VueApexCharts
  }
})
export default class GraphicsTimeLineFailures extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() sgpaapiurl!: string;
  @Prop() token!: string;
  @Prop() bus!: Vue;
  /* data */
  series: Array<object> = [
    {
      name: "Nº de falhas",
      data: []
    }
  ];
  chartOptions: object = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "straight"
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: []
    },
    yaxis: [
      {
        labels: {
          formatter: function(val: number) {
            return val.toFixed(0);
          }
        },
        title: {
          text: "Número de Falhas"
        }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function(y: number) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " falhas";
          }
          return y;
        }
      }
    }
  };

  /* hooks */
  created() {
    this.bus.$on("filterChild", () => {
      this.getLinhaTempoFalhas();
    });
  }

  /* methods */
  getLinhaTempoFalhas() {
    const f = (accumulator: string, currentValue: string) => accumulator + currentValue;

    const dtStart = this.dataStart
      .split("-")
      .reverse()
      .reduce(f);
    const dtEnd = this.dataEnd
      .split("-")
      .reverse()
      .reduce(f);
    return axios
      .get(
        this.sgpaapiurl +
          "/telemetria/linha-tempo-falhas?cd_equipamento=" +
          this.cdEquipamento +
          "&criticidade=" +
          this.criticidade +
          "&spn=" +
          this.spn +
          "&operador=" +
          this.operador +
          "&data_inicio=" +
          dtStart +
          "&data_fim=" +
          dtEnd,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token
          }
        }
      )
      .then(response => {
        const dates = [];
        const data = [];
        const days =
          (new Date(this.dataEnd).getTime() - new Date(this.dataStart).getTime()) /
          (1000 * 3600 * 24);

        for (
          let d = new Date(this.dataStart);
          d <= new Date(this.dataEnd);
          d.setDate(d.getDate() + 1)
        ) {
          let flagFound = false;
          let indexFound = -1;
          const copy = d.toLocaleString("pt-BR", { timeZone: "UTC" });
          const date = copy.split(" ")[0];
          const dtSplit = date.split("/");
          dates.push(dtSplit[0] + "/" + dtSplit[1]);
          for (let i = 0; i < response.data.length; i++) {
            const d = new Date(response.data[i][0]);
            const utc = d.getTime() + d.getTimezoneOffset() * 60000; //This converts to UTC 00:00
            const nd = new Date(utc + 3600000);
            if (
              nd.getDate() === parseInt(dtSplit[0]) &&
              nd.getMonth() + 1 === parseInt(dtSplit[1])
            ) {
              console.log("Entered Here");
              flagFound = true;
              indexFound = i;
            }
          }
          if (flagFound) {
            data.push(response.data[indexFound][1]);
          } else {
            data.push(parseInt("0"));
          }
        }
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            chart: {
              height: 350,
              type: days === 0 ? "scatter" : "line",
              zoom: {
                enabled: false
              }
            },
            markers: {
              size: 6
            },
            xaxis: { categories: dates }
          }
        };
        this.series = [{ data: data }];
      });
  }
  /* computed */
  get dataStart() {
    return gettersEvents.getFilterFailures().dateStart;
  }
  get dataEnd() {
    return gettersEvents.getFilterFailures().dateEnd;
  }
  get criticidade() {
    return gettersEvents.getFilterFailures().criticidade;
  }
  get operador() {
    return gettersEvents.getFilterFailures().operador;
  }
  get spn() {
    return gettersEvents.getFilterFailures().spn;
  }
}

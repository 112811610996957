















import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class BannerTitle extends Vue {
  @Prop() icon!: string;
  @Prop() titleText!: string;
  @Prop({ default: false }) showExpand!: boolean;

  /* Data */
  expanded = true;

  /* Method */
  toggleExpand() {
    this.expanded = !this.expanded;
    this.$emit("onExpanded", this.expanded);
  }
}


















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { gettersTelemetria } from "@/store/Telemetria";
import axios from "axios";

@Component({})
export default class FailureUpdate extends Vue {
  @Prop() updateFailure!:Failures.FailureLevel
  $refs!: {
    form: HTMLFormElement;
  };
  /* Data */
  alert = false;
  message = "";
  color = "";
  valid = true;
  newUpdate !: Failures.FailureLevel;
  moduloRules = [(v: object) => !!v || "Módulo is required"];

  codigoFalhaRules = [
    (v: number) => !!v || "Código is required",
    (v: number) => (v && v.toString().length <= 10) || "Código must be valid"
  ];

  descricaoRules = [
    (v: string) => !!v || "Descrição is required",
    (v: string) => (v && v.length <= 500) || "Descrição must be valid"
  ];
  itemsCriticidadeCliente: Array<Failures.ListCritical> = [];
  itemsModulo = [];
  itemsModeloEquipamento = [];
  
  created(){
    this.newUpdate = {
      ...this.updateFailure
    }
  }
  /* Methods */
  mounted() {
    this.getListaCriticidade();
  }
  validateForm() {
    if (this.$refs.form.validate()) {
      this.putCodigoFalha();
    }
  }
  closeModal() {
    this.$refs.form.reset();
    this.$refs.form.resetValidation();
    this.$emit("close");
  }
  getListaCriticidade() {
    axios
      .get(gettersTelemetria.getUrlSgpa() + "/telemetria/lista-criticidade", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": gettersTelemetria.getToken()
        }
      })
      .then(response => {
        this.itemsCriticidadeCliente = response.data.filter(
          (x: Failures.ListCritical) =>
            x.descNivelAlarme === "Erro" ||
            x.descNivelAlarme === "Alarme" ||
            x.descNivelAlarme === "Alerta" ||
            x.descNivelAlarme === "Critico"
        );
      });
  }
  putCodigoFalha() {
    axios
      .put(
        gettersTelemetria.getUrlSgpa() + "/telemetria/codigos-falha/atualizar/" +
        this.newUpdate.cdId,
        this.newUpdate,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": gettersTelemetria.getToken()
          }
        }
      )
      .then(response => {
        if (response.status == 200) {
          this.closeModal();
          this.$emit("updateFalhasList", "atualizado");
        } else {
          console.log(response);
          this.setAlert();
        }
      })
      .catch(error => {
        console.log("ERROR:", error);
        this.setAlert();
      });
  }
  setAlert() {
    this.alert = true;
    this.message = "Erro ao editar código de falha!";
    this.color = "error";
  }
}






































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { gettersTelemetria } from "@/store/Telemetria";
import axios from "axios";

@Component({})
export default class AlertAdd extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };
  /* Data */
  alert = false;
  message = "";
  color = "";
  cdId = null;
  valid = true;
  tipoEquipamento = "";
  fabricanteEquipamento = "";
  modeloEquipamento = "";
  modelosEquipamento = [];
  parametros: Array<Global.MinMaxPainel> = [];
  tiposEquipamento = [
    { id: 1, nome: "Colhedora" },
    { id: 2, nome: "Transbordo" },
    { id: 40, nome: "Trator de Pneu Leve" }
  ];
  fabricantesEquipamento: { [key: string]: any }[] = [];
  /* Computed */
  get tpEquipamento() {
    return this.tiposEquipamento.map(x => x.id + " - " + x.nome);
  }
  get fbEquipamento() {
    return this.fabricantesEquipamento.map(x => x.cdFabricante + " - " + x.descFabricante);
  }
  get mdEquipamento() {
    if (this.tipoEquipamento && this.fabricanteEquipamento) {
      this.getModelos();
      return false;
    }
    return true;
  }
  get initParametros() {
    if (this.modeloEquipamento && this.fabricanteEquipamento && this.tipoEquipamento) {
      this.getParametros();
      return true;
    }
    this.parametros = [];
    return false;
  }
  /* watch */
  @Watch("fabricanteEquipamento")
  fabricanteEquipamentoChanged() {
    this.modeloEquipamento = "";
  }
  @Watch("tipoEquipamento")
  tipoEquipamentoChanged() {
    this.fabricanteEquipamento = "";
    this.modeloEquipamento = "";
  }
  /* Lifecycle Hooks */
  created() {
    this.getFabricantes();
  }
  /* Methods */
  getFabricantes() {
    axios
      .get(gettersTelemetria.getUrlSgpa() + "/fabricante", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": gettersTelemetria.getToken()
        }
      })
      .then(response => {
        this.fabricantesEquipamento = response.data;
      });
  }

  validate() {
    if (this.$refs.form.validate()) {
      this.send();
    }
  }
  reset() {
    this.$refs.form.reset();
  }
  getModelos() {
    axios
      .get(
        gettersTelemetria.getUrlSgpa() +
          "/telemetria/lista-modelos?tipoEquipamento=" +
          parseInt(this.tipoEquipamento.split("-")[0]) +
          "&fabricante=" +
          parseInt(this.fabricanteEquipamento.split("-")[0]),
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": gettersTelemetria.getToken()
          }
        }
      )
      .then(res => {
        this.modelosEquipamento = res.data.map((x: object[]) => {
          return { value: x[0], text: x[1] };
        });
      });
  }
  getMinMax() {
    axios
      .get(
        gettersTelemetria.getUrlSgpa() +
          "/parametrosRedecan/findByCds?cd_fabricante=" +
          parseInt(this.fabricanteEquipamento.split("-")[0]) +
          "&cd_modelo=" +
          this.modeloEquipamento +
          "&cd_tipo=" +
          parseInt(this.tipoEquipamento.split("-")[0]),
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": gettersTelemetria.getToken()
          }
        }
      )
      .then(response => {
        const data = response.data;
        if (data) {
          for (let i = 0; i < this.parametros.length; i++) {
            if (data[this.parametros[i].campo + "Minimo"] != null) {
              this.parametros[i].minParametro = data[this.parametros[i].campo + "Minimo"];
            } else {
              this.parametros[i].minParametro = this.parametros[i].min + 1;
            }
            if (data[this.parametros[i].campo + "Maximo"] != null) {
              this.parametros[i].maxParametro = data[this.parametros[i].campo + "Maximo"];
            } else {
              this.parametros[i].maxParametro = this.parametros[i].max - 1;
            }
          }
          this.cdId = data["cdId"];
        } else {
          this.cdId = null;
          for (let i = 0; i < this.parametros.length; i++) {
            this.parametros[i].minParametro = this.parametros[i].min + 1;
            this.parametros[i].maxParametro = this.parametros[i].max - 1;
          }
          this.send();
        }
      });
  }
  getParametros() {
    axios
      .get(
        gettersTelemetria.getUrlSgpa() +
          "/telemetria/lista-parametros-tipo-equipamento?tp_equipamento=" +
          parseInt(this.tipoEquipamento.split("-")[0]),
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": gettersTelemetria.getToken()
          }
        }
      )
      .then(response => {
        this.parametros = response.data
          .filter((x: string) => x[4] !== null)
          .map((x: Array<string | number>) => {
            const camp = (x[4] as string).split("_");
            let finalCampo = "";
            for (let i = 0; i < camp.length; i++) {
              if (i === 0) {
                finalCampo += camp[i].toLowerCase();
              } else {
                let campCamel = "";
                for (let j = 0; j < camp[i].length; j++) {
                  if (j === 0) {
                    campCamel += camp[i][j].toUpperCase();
                  } else {
                    campCamel += camp[i][j].toLowerCase();
                  }
                }
                finalCampo += campCamel;
              }
            }
            return {
              desc: x[0],
              min: x[1],
              max: x[2],
              minParametro: "",
              maxParametro: "",
              unidade: x[3],
              campo: finalCampo,
              rules: [
                (v: number | string) => !!v || "Valor Obrigatório",
                (v: number) => (v && v <= x[2]) || "Valor acima do permitido",
                (v: number) => (v && v >= x[1]) || "Valor abaixo do permitido"
              ]
            };
          });
        this.getMinMax();
      });
  }
  async send() {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const minMaxParametros: any = {};
    for (let i = 0; i < this.parametros.length; i++) {
      minMaxParametros[this.parametros[i].campo + "Minimo"] = this.parametros[i].minParametro;
      minMaxParametros[this.parametros[i].campo + "Maximo"] = this.parametros[i].maxParametro;
    }
    minMaxParametros["cdModeloEquipamento"] = this.modeloEquipamento;
    minMaxParametros["cdFabricante"] = parseInt(this.fabricanteEquipamento.split("-")[0]);
    minMaxParametros["cdTpEquipamento"] = parseInt(this.tipoEquipamento.split("-")[0]);
    minMaxParametros["cdId"] = this.cdId;
    await axios
      .post(gettersTelemetria.getUrlSgpa() + "/parametrosRedecan/create_update", minMaxParametros, {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": gettersTelemetria.getToken()
        }
      })
      .then(response => {
        if (response.status == 200) {
          this.alert = true;
          this.message = "Alerta cadastrado com sucesso!";
          this.color = "success";
        }
      })
      .catch(err => {
        console.log(err);
        this.alert = true;
        this.message = "Erro ao cadastrar alerta!";
        this.color = "error";
      });
  }
}

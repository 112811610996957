








import LoadActualState from "./LoadActualState.vue";
import LoadActualStateAlarm from "./LoadActualStateAlarm.vue";
import KafkaMonitoring from "./KafkaMonitoring.vue";
import { mutationsTelemetria } from "@/store/Telemetria";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    LoadActualState,
    LoadActualStateAlarm,
    KafkaMonitoring
  }
})
export default class TelemetriaState extends Vue {
  /* Hooks */
  beforeDestroy() {
    mutationsTelemetria.resetMonitoringState();
  }
}













import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { gettersEvents } from "@/store/Graphics";
import axios from "axios";

@Component({
  components: {
    apexcharts: VueApexCharts
  }
})
export default class GraphicsFailModule extends Vue {
  @Prop() cdEquipamento!: number;
  @Prop() token!: string;
  @Prop() sgpaapiurl!: string;
  @Prop() bus!: Vue;
  /* data */
  series = [
    {
      data: []
    }
  ];
  chartOptions = {
    chart: {
      type: "bar",
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"]
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"]
    },
    xaxis: {
      categories: []
    },
    tooltip: {
      y: {
        formatter: function(y: number) {
          return y.toFixed(0) + " falhas";
        }
      }
    }
  };

  /* hooks */
  created() {
    this.bus.$on("filterChild", () => {
      this.getFalhaPorModulo();
    });
  }

  /* methods */
  getFalhaPorModulo() {
    const f = (accumulator: string, currentValue: string) => accumulator + currentValue;
    const dtStart = this.dataStart
      .split("-")
      .reverse()
      .reduce(f);
    const dtEnd = this.dataEnd
      .split("-")
      .reverse()
      .reduce(f);
    return axios
      .get(
        this.sgpaapiurl +
          "/telemetria/falha-por-modulo?cd_equipamento=" +
          this.cdEquipamento +
          "&criticidade=" +
          this.criticidade +
          "&spn=" +
          this.spn +
          "&operador=" +
          this.operador +
          "&data_inicio=" +
          dtStart +
          "&data_fim=" +
          dtEnd,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": this.token
          }
        }
      )
      .then(response => {
        const index = response.data
          .map((x: Array<object>) => x[1])
          .findIndex((x: string) => x === "Não cadastrado");
        if (index != -1) {
          response.data.splice(index, 1);
        }

        this.series = [{ data: response.data.map((x: Array<object>) => x[0]) }];
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: { categories: response.data.map((x: Array<object>) => x[1]) }
          }
        };
      });
  }

  /* computed */
  get dataStart() {
    return gettersEvents.getFilterFailures().dateStart;
  }
  get dataEnd() {
    return gettersEvents.getFilterFailures().dateEnd;
  }
  get criticidade() {
    return gettersEvents.getFilterFailures().criticidade;
  }
  get operador() {
    return gettersEvents.getFilterFailures().operador;
  }
  get spn() {
    return gettersEvents.getFilterFailures().spn;
  }
}
























































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import FailureAdd from "./FailureAdd.vue";
import FailureUpdate from "./FailureUpdate.vue";
import FailureDelete from "./FailureDelete.vue";
import { gettersTelemetria } from "@/store/Telemetria";
@Component({
  components: {
    FailureAdd,
    FailureUpdate,
    FailureDelete
  }
})
export default class Failure extends Vue {
  /* Data */
  alert = false;
  message = "";
  color = "";
  closeMenu = false;
  dialog = false;
  dialogUpdate = false;
  dialogDelete = false;
  configCodigoFalhaList: Array<Failures.FailureLevel> = [];
  criticalidadeList: Array<Failures.ListCritical> = [];
  search = "";
  loading = false;
  selectedRow = 0;
  rowsPerPageItems = [5, 10];
  itemsPerPage = 5;
  data: Array<object> = [];
  expand = false;
  selected = [];
  headers = [
    { text: "Código", value: "cdFalhaCriticidadeFabricante" },
    { text: "Descrição da Falha", value: "descFalha" },
    {
      text: "Nivel de Criticalidade",
      value: "cdCriticidadeCliente.descNivelAlarme"
    },
    { text: "Ações", value: "acoes" }
  ];
  failure = {};
  /* Computed */

  /* Methods */
  changeAndPost(data: object) {
    this.closeMenu = true;
    this.postCodigosFalha(data);
  }
  updateFalhasList(mensagem: string) {
    this.getCodigosFalha();
    this.alert = true;
    this.message = "Código de falhas " + mensagem + " com sucesso!";
    this.color = "success";
  }
  itemChanged(item: Failures.FailureLevel, event: number) {
    const idxCriticalidade = this.criticalidadeList
      .map(e => e.cdNivelAlarme)
      .findIndex(x => x === event);
    this.configCodigoFalhaList.map(e => {
      if (e.cdId == item.cdId) {
        Object.assign(e, {
          cdCriticidadeCliente: this.criticalidadeList[idxCriticalidade]
        });
      }
    });
    this.postCodigosFalha(item);
  }
  postCodigosFalha(data: object) {
    axios
      .post(
        gettersTelemetria.getUrlSgpa() + "/telemetria/codigos-falha/salvar",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": gettersTelemetria.getToken()
          }
        }
      )
      .catch(error => {
        console.log("ERROR:", error);
      });
  }
  getCodigosFalha() {
    axios
      .get(gettersTelemetria.getUrlSgpa() + "/telemetria/codigos-falha", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": gettersTelemetria.getToken()
        }
      })
      .then(response => {  
        this.configCodigoFalhaList = response.data;

        response.data.map((element: any) => {
          element.cdFalhaCriticidadeFabricante = element.cdCriticidadeFabricante != null ? element.cdFalha + "." + element.cdCriticidadeFabricante : element.cdFalha;
          return element;
        });

        this.setData(response.data);
      });
  }
  getListaCriticidade() {
    axios
      .get(gettersTelemetria.getUrlSgpa() + "/telemetria/lista-criticidade", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": gettersTelemetria.getToken()
        }
      })
      .then(response => {
        this.criticalidadeList = response.data.filter(
          (x: Failures.ListCritical) =>
            x.descNivelAlarme === "Erro" ||
            x.descNivelAlarme === "Alarme" ||
            x.descNivelAlarme === "Alerta" ||
            x.descNivelAlarme === "Critico"
        );
      });
  }
  setData(response: Array<object>) {
    this.data = response;
  }
  mounted() {
    this.getCodigosFalha();
    this.getListaCriticidade();
  }
  updateFalha(row: object) {
    this.dialogUpdate = true;
    this.failure = row;
  }
  deleteFalha(row: object) {
    this.dialogDelete = true;
    this.failure = row;
  }
  closeDelete() {
    this.dialogDelete = false;
  }
}

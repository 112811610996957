
















































import { Component, Vue } from "vue-property-decorator";
import TopMenuCard from "./TopMenuCard.vue";
import TopMenuBtn from "./TopMenuBtn.vue";
import { gettersTelemetria } from "@/store/Telemetria";
@Component({
  components: {
    TopMenuCard,
    TopMenuBtn
  }
})
export default class TopMenu extends Vue {
  /* Computed */
  get counts() {
    return gettersTelemetria.getCounts();
  }
  /* Methods */
  onFailure() {
    this.$emit("onFailure");
  }
  onAlert() {
    this.$emit("onAlert");
  }
}

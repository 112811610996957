import Vue from "vue";
import moment from "moment";

export const state = Vue.observable({
  filterFailures: {
    dateStart: new Date().toISOString().substr(0, 10),
    dateEnd: new Date().toISOString().substr(0, 10),
    criticidade: 0,
    operador: "",
    spn: ""
  },
  filterSensors: {
    dataInicio: moment(new Date()).format("DD/MM/YYYY") + " 00:00:00",
    dataFim: moment(new Date()).format("DD/MM/YYYY") + " 23:59:59",
    tempo: 0,
    parametros: ["Pressao Ar Coletor", "Pressao Oleo Trans.", "Pressao Corte", "Pressao Picador"]
  },
  pList: [
    { parametro: "Código do Estado", min: 0, max: 0, unidade: "" },
    { parametro: "Pressão ar Coletor", min: 1, max: 6000, unidade: "Kpa" },
    { parametro: "Pressão óleo transmissão", min: 1, max: 6000, unidade: "PSI" },
    { parametro: "Pressão de Corte", min: 1, max: 6000, unidade: "PSI" },
    { parametro: "Pressão do Picador", min: 1, max: 6000, unidade: "PSI" },
    { parametro: "Pressão div Esquerda", min: 1, max: 6000, unidade: "PSI" },
    { parametro: "Voltagem da Bateria", min: 1, max: 36, unidade: "Volts" },
    { parametro: "Sensor Radar", min: 1, max: 60, unidade: "Km/h" },
    { parametro: "Velocidade de Transmissão", min: 1, max: 45, unidade: "Km/h" },
    { parametro: "Pressão div Direita", min: 1, max: 6000, unidade: "PSI" },
    { parametro: "Temperatura Turbo Comprenssor", min: 1, max: 199, unidade: "°C" },
    { parametro: "Temperatura Rad Carga", min: 1, max: 199, unidade: "°C" },
    { parametro: "Tempo de Combustível", min: 1, max: 100, unidade: "Litro/Hora" },
    { parametro: "Horimetro", min: 1, max: 90000, unidade: "Horas" },
    { parametro: "Velocidade Rolamento Esquerdo", min: 1, max: 45, unidade: "Km/h" },
    { parametro: "Nivel do Combustível", min: 1, max: 150, unidade: "%" },
    { parametro: "Pressão Embreagem", min: 1, max: 100, unidade: "%" },
    { parametro: "Pressao Barometrica", min: 1, max: 6000, unidade: "Kpa" },
    { parametro: "Pressão de Óleo", min: 1, max: 6000, unidade: "Kpa" },
    { parametro: "Velocidade Rolamento Direito", min: 1, max: 45, unidade: "Km/h" },
    { parametro: "Hodometro", min: 1, max: 1000000, unidade: "Km" },
    { parametro: "Temperatura Óleo Transmissão", min: 1, max: 199, unidade: "°C" },
    { parametro: "Horas Elevador", min: 1, max: 90000, unidade: "Horas" },
    { parametro: "Temperatura Coletor do Ar", min: 1, max: 199, unidade: "°C" },
    { parametro: "Temperatura combustivel do Motor", min: 1, max: 199, unidade: "°C" },
    { parametro: "Temperatura Fluido Hidraulico", min: 1, max: 199, unidade: "°C" },
    { parametro: "Rpm do Motor", min: 1, max: 8000, unidade: "RPM" },
    { parametro: "Torque do Motor", min: 1, max: 150, unidade: "%" },
    { parametro: "Temperatura Óleo TDP", min: 1, max: 199, unidade: "°C" },
    { parametro: "Temperatura Óleo do Motor", min: 1, max: 199, unidade: "°C" }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ] as Array<{ [key: string]: any }>,
  criticidadeList: ["", "247", "246", "1"] as Array<string>,

  tempoList: [
    { text: "30 minutos", value: 1800 },
    { text: "1 hora", value: 3600 },
    { text: "2 horas", value: 7200 },
    { text: "5 horas", value: 18000 },
    { text: "10 horas", value: 36000 },
    { text: "15 horas", value: 54000 },
    { text: "20 horas", value: 72000 },
    { text: "24 horas", value: 86400 }
  ] as Array<Global.TempoList>,
  tempoListPainel: [
    { text: "10 minutos", value: 10 },
    { text: "15 minutos", value: 15 },
    { text: "30 minutos", value: 30 },
    { text: "1 hora", value: 60 },
    { text: "3 horas", value: 180 },
    { text: "7 horas", value: 420 }
  ] as Array<Global.TempoList>,
  minMaxPainel: [] as Array<Global.MinMaxPainel>,
  valoresGraficoTendencia: {
    parametroUm: "",
    parametroDois: "",
    parametroTres: "",
    parametroQuatro: "",
    tempo: 1800
    // dataInicio: new Date().toISOString()
  },
  perfilCarga: {
    dataInicio: "",
    dataFim: ""
  },
  perfilCargaDados: {
    dadosCriticidade: [] as { [key: string]: any }[],
    dados: [] as { [key: string]: any }[]
  },
  perfilCargaDadosRaw: {
    dadosCriticidade: [] as { [key: string]: any }[],
    dados: [] as { [key: string]: any }[]
  },
  valoresGraficoPainel: {
    parametroUm: "",
    minParametroUm: 0,
    maxParametroUm: 0,
    medidaParametroUm: "",
    parametroDois: "",
    minParametroDois: 0,
    maxParametroDois: 0,
    medidaParametroDois: "",
    parametroTres: "",
    minParametroTres: 0,
    maxParametroTres: 0,
    medidaParametroTres: "",
    parametroQuatro: "",
    minParametroQuatro: 0,
    maxParametroQuatro: 0,
    medidaParametroQuatro: "",
    tempo: 10,
    dataInicio: moment(new Date()).format("DD/MM/YYYY") + " 00:00:00",
    dataFim: moment(new Date()).format("DD/MM/YYYY") + " 23:59:59"
  }
});

export const gettersEvents = {
  getFilterFailures: () => state.filterFailures,
  getCriticidadeList: () => state.criticidadeList,
  getParametrosList: () => state.pList,
  getTempoList: () => state.tempoList,
  getTempoListPainel: () => state.tempoListPainel,
  getPerfilCargaDados: () => state.perfilCargaDados,
  getMinMaxPainel: () => state.minMaxPainel,
  getPerfilCarga: () => state.perfilCarga,
  getPerfilCargaDadosRaw: () => state.perfilCargaDadosRaw,
  getValoresGraficoTendencia: () => state.valoresGraficoTendencia,
  getValoresGraficoPainel: () => state.valoresGraficoPainel,
  getFilterSensors: () => state.filterSensors
};

export const mutationsEvents = {
  setPerfilCargaDadosCriticidade(payload: { [key: string]: any }[]) {
    state.perfilCargaDados.dadosCriticidade = payload;
  },
  setPerfilCargaDados(payload: { [key: string]: any }[]) {
    state.perfilCargaDados.dados = payload;
  },
  setPerfilCargaDadosRawCriticidade(payload: { [key: string]: any }[]) {
    state.perfilCargaDadosRaw.dadosCriticidade = payload;
  },
  setPerfilCargaDadosRaw(payload: { [key: string]: any }[]) {
    state.perfilCargaDadosRaw.dados = payload;
  },
  setDataInicioPC(payload: string) {
    state.perfilCarga.dataInicio = payload;
  },
  setDataFimPC(payload: string) {
    state.perfilCarga.dataFim = payload;
  },
  setMinMaxPainel(payload: Array<Global.MinMaxPainel>) {
    state.minMaxPainel = payload;
  },
  setMinParametroUm(payload: number) {
    state.valoresGraficoPainel.minParametroUm = payload;
  },
  setMaxParametroUm(payload: number) {
    state.valoresGraficoPainel.maxParametroUm = payload;
  },
  setMinParametroDois(payload: number) {
    state.valoresGraficoPainel.minParametroDois = payload;
  },
  setMaxParametroDois(payload: number) {
    state.valoresGraficoPainel.maxParametroDois = payload;
  },
  setMinParametroTres(payload: number) {
    state.valoresGraficoPainel.minParametroTres = payload;
  },
  setMaxParametroTres(payload: number) {
    state.valoresGraficoPainel.maxParametroTres = payload;
  },
  setMinParametroQuatro(payload: number) {
    state.valoresGraficoPainel.minParametroQuatro = payload;
  },
  setMaxParametroQuatro(payload: number) {
    state.valoresGraficoPainel.maxParametroQuatro = payload;
  },
  setMedidaParametroUm(payload: string) {
    state.valoresGraficoPainel.medidaParametroUm = payload;
  },
  setMedidaParametroDois(payload: string) {
    state.valoresGraficoPainel.medidaParametroDois = payload;
  },
  setMedidaParametroTres(payload: string) {
    state.valoresGraficoPainel.medidaParametroTres = payload;
  },
  setMedidaParametroQuatro(payload: string) {
    state.valoresGraficoPainel.medidaParametroQuatro = payload;
  },
  setParametroUmPainel(payload: string) {
    state.valoresGraficoPainel.parametroUm = payload;
  },
  setParametroDoisPainel(payload: string) {
    state.valoresGraficoPainel.parametroDois = payload;
  },
  setParametroTresPainel(payload: string) {
    state.valoresGraficoPainel.parametroTres = payload;
  },
  setParametroQuatroPainel(payload: string) {
    state.valoresGraficoPainel.parametroQuatro = payload;
  },
  setTempoPainel(payload: number) {
    state.valoresGraficoPainel.tempo = payload;
  },
  setParametroUm(payload: string) {
    state.valoresGraficoTendencia.parametroUm = payload;
  },
  setParametroDois(payload: string) {
    state.valoresGraficoTendencia.parametroDois = payload;
  },
  setParametroTres(payload: string) {
    state.valoresGraficoTendencia.parametroTres = payload;
  },
  setParametroQuatro(payload: string) {
    state.valoresGraficoTendencia.parametroQuatro = payload;
  },
  setDateStart(payload: string) {
    state.filterFailures.dateStart = payload;
  },
  setDateEnd(payload: string) {
    state.filterFailures.dateEnd = payload;
  },
  setCriticidade(payload: number) {
    console.log(payload);
    state.filterFailures.criticidade = payload;
  },
  setOperador(payload: string) {
    state.filterFailures.operador = payload;
  },
  setSpn(payload: string) {
    state.filterFailures.spn = payload;
  },
  setDataInicio(payload: string) {
    state.filterSensors.dataInicio = payload;
  },
  setDataFim(payload: string) {
    state.filterSensors.dataFim = payload;
  },
  setParametros(payload: string[]) {
    state.filterSensors.parametros = payload;
  },
  setTempo(payload: number) {
    state.filterSensors.tempo = payload;
  },
  clearData() {
    state.filterFailures = {
      dateStart: new Date().toISOString().substr(0, 10),
      dateEnd: new Date().toISOString().substr(0, 10),
      criticidade: 0,
      operador: "",
      spn: ""
    };
    state.filterSensors = {
      dataInicio: moment(new Date()).format("DD/MM/YYYY") + " 00:00:00",
      dataFim: moment(new Date()).format("DD/MM/YYYY") + " 23:59:59",
      tempo: 0,
      parametros: ["Pressao Ar Coletor", "Pressao Oleo Trans.", "Pressao Corte", "Pressao Picador"]
    };
    state.valoresGraficoTendencia = {
      parametroUm: "",
      parametroDois: "",
      parametroTres: "",
      parametroQuatro: "",
      tempo: 1800
    };
    state.valoresGraficoPainel = {
      parametroUm: "",
      minParametroUm: 0,
      maxParametroUm: 0,
      parametroDois: "",
      minParametroDois: 0,
      maxParametroDois: 0,
      parametroTres: "",
      minParametroTres: 0,
      maxParametroTres: 0,
      parametroQuatro: "",
      minParametroQuatro: 0,
      maxParametroQuatro: 0,
      medidaParametroUm: "",
      medidaParametroDois: "",
      medidaParametroTres: "",
      medidaParametroQuatro: "",
      tempo: 10,
      dataInicio: moment(new Date()).format("DD/MM/YYYY") + " 00:00:00",
      dataFim: moment(new Date()).format("DD/MM/YYYY") + " 23:59:59"
    };
  }
};






























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import BannerTitle from "./Common/BannerTitle.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { gettersTelemetria, mutationsTelemetria } from "@/store/Telemetria";
import axios from "axios";
@Component({
  components: {
    BannerTitle
  }
})
export default class RecentFailures extends Vue {
  /* Data */
  show = true;
  search = null;
  token = gettersTelemetria.getToken();
  status = [
    { cor: "#f92121", valor: 0, porcent: 0, text: "Crítico" },
    { cor: "#ff7e22", valor: 0, porcent: 0, text: "Alarme" },
    { cor: "#ffc107", valor: 0, porcent: 0, text: "Alerta" },
    { cor: "#0a16f2", valor: 0, porcent: 0, text: "Erro" }
  ];
  nivelCriticidade: { [key: string]: any } = {};
  falhas: { [key: string]: any }[] = [];
  countTotal = 0;

  /* Hooks */
  created() {
    this.getNivelCriticidade();
  }

  /* Computed */

  get failures() {
    return gettersTelemetria
      .getFalhas()
      .map(x => {
        return {
          id: x.cdEquipamento,
          cdId: x.cdId,
          icone: "mdi-engine",
          classificacao: x.fgClassificacao,
          titulo: x.descAlarme,
          cdFalha: x.cdEspecialAlarme
        };
      })
      .concat(
        gettersTelemetria.getFalhasParametro().map(x => {
          return {
            id: x.cdEquipamento,
            cdId: x.cdId,
            icone: "mdi-alert",
            classificacao: x.fgClassificacao,
            titulo: x.descAlarme,
            cdFalha: x.cdEspecialAlarme
          };
        })
      );
  }

  /* Methods */
  onClickFilter() {
    if (this.search) {
      const falhasFiltered: { [key: string]: any }[] = [];
      for (let index = 0; index < this.falhas.length; index++) {
        if (this.search === this.falhas[index].codigo) {
          falhasFiltered.push(this.falhas[index]);
        }
      }
      this.falhas = falhasFiltered;
    } else {
      this.getDatas();
    }
  }
  getDatas() {
    this.falhas = [];
    mutationsTelemetria.setFalhas([]);
    this.countTotal = 0;
    if (this.failures.length !== 0) {
      axios({
        method: "post",
        url: gettersTelemetria.getUrlSgpa() + "/telemetria/lista-data",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": this.token
        },
        data: {
          ids: this.failures.map(x => x.cdId)
        }
      }).then((response: any) => {
        const failures = [];
        for (let index = 0; index < response.data.length; index++) {
          const element: any = this.failures[
            this.failures.findIndex(x => x.cdId === response.data[index].id)
          ];
          const data = new Date(response.data[index].data);
          element.data =
            data.getDate() +
            "/" +
            (data.getMonth() + 1) +
            "/" +
            data.getFullYear() +
            " - " +
            data.getHours() +
            ":" +
            data.getMinutes() +
            ":" +
            data.getSeconds();
          element.justificativa = response.data[index].justificativa;
          element.vlAlarme = response.data[index].vl_alarme;
          if (element.justificativa === null) {
            failures.push(element);
          }
        }
        this.getCriticidade(failures.filter(x => x.classificacao === 9));
        this.getUnidadeMedida(failures.filter(x => x.classificacao === 10));
      });
    } else {
      this.falhas = [];
      mutationsTelemetria.setFalhas([]);
    }
  }

  getUnidadeMedida(fail: { [key: string]: any }[]) {
    if (fail.length !== 0) {
      axios
        .get(
          gettersTelemetria.getUrlSgpa() +
            "/telemetria/lista-unidade-medida?descs=" +
            fail.map(x => x.titulo),
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": this.token
            }
          }
        )
        .then((response: any) => {
          for (let index = 0; index < fail.length; index++) {
            for (let indexTwo = 0; indexTwo < response.data.length; indexTwo++) {
              if (fail[index].titulo === response.data[indexTwo][0]) {
                fail[index].codigo =
                  "Valor do parâmetro: " + fail[index].vlAlarme + " " + response.data[indexTwo][1];
              }
            }
            this.countTotal = this.countTotal + 1;
            fail[index].cor = "#585858";
          }
          this.falhas = this.falhas.concat(fail);
          mutationsTelemetria.setFalhas(this.falhas);
          this.status[0].porcent = Math.round((this.status[0].valor / this.countTotal) * 100);
          this.status[1].porcent = Math.round((this.status[1].valor / this.countTotal) * 100);
          this.status[2].porcent = Math.round((this.status[2].valor / this.countTotal) * 100);
          this.status[3].porcent = Math.round((this.status[3].valor / this.countTotal) * 100);
        });
    }
  }

  getNivelCriticidade() {
    axios
      .get(gettersTelemetria.getUrlSgpa() + "/telemetria/lista-nivel-criticidade", {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": this.token
        }
      })
      .then(response => {
        const data = response.data;
        this.nivelCriticidade = {
          alarme: data.alarme,
          alerta: data.alerta,
          critico: data.critico,
          erro: data.erro
        };
      });
  }

  getCriticidade(fail: { [key: string]: any }[]) {
    if (fail.length !== 0) {
      axios
        .get(
          gettersTelemetria.getUrlSgpa() +
            "/telemetria/lista-criticidade-por-cd-id?cdIds=" +
            fail.map(x => x.cdFalha).toString(),
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": this.token
            }
          }
        )
        .then((response: any) => {
          let countCritico = 0;
          let countAlerta = 0;
          let countAlarme = 0;
          let countErro = 0;
          for (let index = 0; index < response.data.length; index++) {
            for (let indexTwo = 0; indexTwo < fail.length; indexTwo++) {
              if (fail[indexTwo].cdFalha === response.data[index][0]) {
                if (response.data[index][2] === this.nivelCriticidade.critico) {
                  fail[indexTwo].cor = "#f92121";
                  countCritico = countCritico + 1;
                } else if (response.data[index][2] === this.nivelCriticidade.alerta) {
                  fail[indexTwo].cor = "#ffc107";
                  countAlerta = countAlerta + 1;
                } else if (response.data[index][2] === this.nivelCriticidade.alarme) {
                  fail[indexTwo].cor = "#ff7e22";
                  countAlarme = countAlarme + 1;
                } else if (response.data[index][2] === this.nivelCriticidade.erro) {
                  fail[indexTwo].cor = "#0a16f2";
                  countErro = countErro + 1;
                } else {
                  fail[indexTwo].cor = "#585858";
                }
                fail[indexTwo].titulo = response.data[index][1];
                this.countTotal = this.countTotal + 1;
                if (response.data[index][4] === null) {
                  fail[indexTwo].codigo = response.data[index][3] + ".0";
                } else {
                  fail[indexTwo].codigo = response.data[index][3] + "." + response.data[index][4];
                }
              }
            }
          }

          for (let indexTwo = 0; indexTwo < fail.length; indexTwo++) {
            if (!fail[indexTwo].cor) {
              fail[indexTwo].cor = "#585858";
              this.countTotal = this.countTotal + 1;
              fail[indexTwo].codigo = "0.0";
            }
          }

          this.falhas = this.falhas.concat(fail);
          mutationsTelemetria.setFalhas(this.falhas);
          this.status[0].valor = countCritico;
          this.status[0].porcent = Math.round((countCritico / this.countTotal) * 100);
          this.status[1].valor = countAlarme;
          this.status[1].porcent = Math.round((countAlarme / this.countTotal) * 100);
          this.status[2].valor = countAlerta;
          this.status[2].porcent = Math.round((countAlerta / this.countTotal) * 100);
          this.status[3].valor = countErro;
          this.status[3].porcent = Math.round((countErro / this.countTotal) * 100);
        });
    } else {
      this.status[0].valor = 0;
      this.status[0].porcent = 0;
      this.status[1].valor = 0;
      this.status[1].porcent = 0;
      this.status[2].valor = 0;
      this.status[2].porcent = 0;
      this.status[3].valor = 0;
      this.status[3].porcent = 0;
    }
  }

  changeShow() {
    this.show = !this.show;
  }

  changeJustificativa(i: number) {
    axios.put(
      gettersTelemetria.getUrlSgpa() + "/telemetria/update-justificativa/" + this.falhas[i].cdId,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": this.token
        }
      }
    );
  }

  closeFail(i: number) {
    this.changeJustificativa(i);
    this.getDatas();
  }

  @Watch("failures") onEquipamentosAtivos(): void {
    this.getDatas();
  }
}

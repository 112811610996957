



































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mutationsEvents } from "@/store/Graphics";
import MaintenanceDetail from "./MaintenanceDetail.vue";
import GraphicsAvailability from "../graphics/GraphicsAvailability.vue";
import GraphicsTimeLineFailures from "../graphics/GraphicsTimeLineFailures.vue";
import GraphicsFailModule from "../graphics/GraphicsFailModule.vue";
import GraphicsDurationFailure from "../graphics/GraphicsDurationFailure.vue";
import GraphicsPanelTemperature from "../graphics/GraphicsPanelTemperature.vue";
import GraphicsCounterManeuver from "../graphics/GraphicsCounterManeuver.vue";
import GraphicsWeatherTrend from "../graphics/GraphicsWeatherTrend.vue";
import GraphicsHistoricalFailures from "../graphics/GraphicsHistoricalFailures.vue";
import GraphicsRankingRecurrence from "../graphics/GraphicsRankingRecurrence.vue";
import MenuPanelTemperature from "../menu/MenuPanelTemperature.vue";
import MenuWeatherTrend from "../menu/MenuWeatherTrend.vue";
import MenuFilterHistory from "../menu/MenuFilterHistory.vue";
import { gettersTelemetria } from "@/store/Telemetria";
import MaintenanceFailures from "./MaintenanceFailures.vue";
import MaintenanceSensors from "./MaintenanceSensors.vue";
@Component({
  components: {
    MaintenanceDetail,
    MaintenanceFailures,
    GraphicsAvailability,
    GraphicsTimeLineFailures,
    GraphicsFailModule,
    GraphicsDurationFailure,
    GraphicsPanelTemperature,
    GraphicsCounterManeuver,
    GraphicsWeatherTrend,
    GraphicsHistoricalFailures,
    GraphicsRankingRecurrence,
    MenuPanelTemperature,
    MenuWeatherTrend,
    MenuFilterHistory,
    MaintenanceSensors
  }
})
export default class Maintenance extends Vue {
  @Prop() equipamento!: Global.Equipamento;
  /* Data */
  dateStart = new Date().toISOString().substr(0, 10);
  tab = null;
  dateEnd = new Date().toISOString().substr(0, 10);
  criticality = 0;
  itemsCritical: Array<Failures.ListCritical> = [];
  menu1 = false;
  menu2 = false;
  tipoEquipamento = null;
  dataGraficoHistoricoFalhas: Array<object> = [];
  /* Computed */

  get token() {
    return gettersTelemetria.getToken();
  }

  get moniturl() {
    return gettersTelemetria.getUrlMonit();
  }

  get cdCliente() {
    return gettersTelemetria.getCdCliente();
  }
  /* Hooks */
  destroyed() {
    mutationsEvents.clearData();
  }
  /* Methods */
  allowDates = (val: string) => new Date(val) <= new Date();
  getDataToExportGraficoHistoricoFalhas(dataToExport: object) {
    this.dataGraficoHistoricoFalhas.push(dataToExport);
  }
}
